import React from "react";
import ContactUs from "../components/contactus/ContactUs";
import QueryForm from "../components/contactus/QueryForm";

const ContactPage = () => {
  return (
    <div>
      <ContactUs />
      {/* <QueryForm /> */}
    </div>
  );
};

export default ContactPage;
