import React from "react";
import Header from "../components/Header";
import Hero from "../components/IntroPage/Hero";
import FeatureHomepage from "../components/IntroPage/FeatureHomepage";
import FaqSection from "../components/IntroPage/FaqSection";

const HomePage = () => {
  return (
    <div>
      <Hero />
      {/* <FeatureHomepage /> */}
      <FaqSection />
    </div>
  );
};

export default HomePage;
