import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import img1 from "../../images/Designer8.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import Cookies from "js-cookie";
const SignUpButton = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSignupSuccess = (resp, type) => {
    Cookies.set("token", resp.token);
    Cookies.set("userName", resp.userName);
    Cookies.set("email", resp.email);
    console.log(resp, "signup");
    if (type == "google") {
      navigate("/");
    } else {
      navigate("/signup/otpform");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const { userName, email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await AuthService.register(userName, email, password);
      console.log("Signup successful", response);
      if (response.token) {
        handleSignupSuccess(response, "email");
      }
      setloading(false);
    } catch (error) {
      toast.warning(`${error.message}`, {
        position: "top-right",
      });
      setloading(false);
      setError(error.message || "Signup failed");
    }
  };

  const Googlelogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setloading(true);
      try {
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );

        if (!res.ok) {
          throw new Error("Failed to fetch user info");
        }

        const userInfo = await res.json();
        console.log("User Info:", userInfo);
        const { email, name, picture } = userInfo;

        const response = await AuthService.registerWithGoogle(
          email,
          name,
          picture,
          userInfo.sub
        );

        console.log("Signup successful", response);
        if (response.token) {
          handleSignupSuccess(response, "google");
        }
        setloading(false);
      } catch (error) {
        toast.warning(`${error.message}`, {
          position: "top-right",
        });
        console.error("Error fetching user info:", error);
        setloading(false);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setloading(false);
    },
  });

  return (
    // <div className="flex flex-row items-center">
    //   <ToastContainer />
    //   {loading && <Loading work="Signing Up" />}
    //   <div className="w-1/2">
    //     <img className="h-[100vh]" alt="hero" src={img1} />
    //   </div>

    //   <div className=" bg-white w-1/2 py-6 sm:py-8 lg:py-12">
    //     <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
    //       <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">
    //         Sign up
    //       </h2>
    //       <form
    //         className="mx-auto max-w-lg rounded-lg border"
    //         onSubmit={handleSubmit}
    //       >
    //         <div className="flex flex-col gap-4 p-4 md:p-8">
    //           <div>
    //             <label
    //               htmlFor="userName"
    //               className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
    //             >
    //               User Name
    //             </label>
    //             <input
    //               name="userName"
    //               type="text"
    //               className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
    //               required
    //               value={formData.userName}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="email"
    //               className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
    //             >
    //               Email
    //             </label>
    //             <input
    //               name="email"
    //               type="email"
    //               className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
    //               required
    //               value={formData.email}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="password"
    //               className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
    //             >
    //               Password
    //             </label>
    //             <input
    //               name="password"
    //               type="password"
    //               className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
    //               required
    //               value={formData.password}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="confirmPassword"
    //               className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
    //             >
    //               Confirm Password
    //             </label>
    //             <input
    //               name="confirmPassword"
    //               type="password"
    //               className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
    //               required
    //               value={formData.confirmPassword}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <button
    //             type="submit"
    //             className="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base"
    //           >
    //             Sign Up
    //           </button>
    //           <div className="relative flex items-center justify-center">
    //             <span className="absolute inset-x-0 h-px bg-gray-300" />
    //             <span className="relative bg-white px-4 text-sm text-gray-400">
    //               Sign up with social
    //             </span>
    //           </div>

    //           <div className="relative flex w-full group">
    //             <button
    //               type="button"
    //               disabled={true}
    //               className="flex w-full cursor-not-allowed items-center justify-center gap-2 rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 group-hover:blur"
    //             >
    //               <svg
    //                 className="h-5 w-5 shrink-0"
    //                 width={24}
    //                 height={24}
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   clipRule="evenodd"
    //                   d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
    //                   fill="white"
    //                 />
    //               </svg>
    //               Continue with GitHub
    //             </button>
    //             <div className="absolute inset-0 w-full h-full bg-opacity-60 backdrop-blur-md flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
    //               <div className="flex z-10 cursor-pointer justify-center p-3 bg-green-200 rounded-xl flex-row space-x-3 items-center">
    //                 <span className="text-lg font-semibold text-gray-800 flex items-center">
    //                   Coming Soon
    //                 </span>
    //                 <svg
    //                   className="text-2xl justify-center items-center"
    //                   width={24}
    //                   height={24}
    //                   viewBox="0 0 24 24"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                 >
    //                   <path
    //                     d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
    //                     fill="currentColor"
    //                   />
    //                 </svg>
    //               </div>
    //             </div>
    //           </div>

    //           <button
    //             type="button"
    //             onClick={() => Googlelogin()}
    //             className="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base"
    //           >
    //             <svg
    //               className="h-5 w-5 shrink-0"
    //               width={24}
    //               height={24}
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M23.7449 12.27C23.7449 11.48 23.6749 10.73 23.5549 10H12.2549V14.51H18.7249C18.4349 15.99 17.5849 17.24 16.3249 18.09V21.09H20.1849C22.4449 19 23.7449 15.92 23.7449 12.27Z"
    //                 fill="#4285F4"
    //               />
    //               <path
    //                 d="M12.2549 24C15.4949 24 18.2049 22.92 20.1849 21.09L16.3249 18.09C15.2449 18.81 13.8749 19.25 12.2549 19.25C9.12492 19.25 6.47492 17.14 5.52492 14.29H1.54492V17.38C3.51492 21.3 7.56492 24 12.2549 24Z"
    //                 fill="#34A853"
    //               />
    //               <path
    //                 d="M5.52488 14.09C5.27488 13.57 5.14488 12.8 5.14488 12C5.14488 11.2 5.28488 10.43 5.52488 9.71V6.62H1.54488C0.724882 8.24 0.254883 10.06 0.254883 12C0.254883 13.94 0.724882 15.76 1.54488 17.38L5.52488 14.29Z"
    //                 fill="#FBBC05"
    //               />
    //               <path
    //                 d="M12 5.38C14.0249 5.38 15.6049 5.99 16.8549 6.55L20.2749 3.13C18.2049 1.19 15.4949 0 12.2549 0C7.56492 0 3.51492 2.7 1.54492 6.62L5.52492 9.71C6.47492 6.86 9.12492 5.38 12.2549 5.38Z"
    //                 fill="#EA4335"
    //               />
    //             </svg>
    //             Continue with Google
    //           </button>
    //         </div>
    //         <p className="text-sm flex justify-center mb-2 font-light text-gray-500 dark:text-gray-400">
    //           Already have an account yet?{" "}
    //           <Link
    //             to="/login"
    //             className="font-medium text-primary-600 hover:underline dark:text-primary-500"
    //           >
    //             Login
    //           </Link>
    //         </p>
    //       </form>
    //     </div>
    //   </div>
    // </div>

    <div className="flex flex-row  lg:flex-row items-center">
      <ToastContainer />
      {loading && <Loading work="Signing Up" />}

      <div className=" relative hidden h-screen w-full lg:flex lg:w-1/2 flex-col justify-center items-center bg-cover bg-center">
        <img className="h-[100vh]" alt="hero" src={img1} />{" "}
      </div>
      <div className=" bg-white w-full lg:w-1/2 min-h-screen py-6 sm:py-8 ">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">
            Sign up
          </h2>
          <form
            className="mx-auto max-w-lg rounded-lg border"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-4 p-4 md:p-8">
              <div>
                <label
                  htmlFor="userName"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  User Name
                </label>
                <input
                  name="userName"
                  type="text"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={formData.userName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Password
                </label>
                <input
                  name="password"
                  type="password"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Confirm Password
                </label>
                <input
                  name="confirmPassword"
                  type="password"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base"
              >
                Sign Up
              </button>
              <div className="relative flex items-center justify-center">
                <span className="absolute inset-x-0 h-px bg-gray-300" />
                <span className="relative bg-white px-4 text-sm text-gray-400">
                  Sign up with social
                </span>
              </div>

              <div className="relative flex w-full group">
                <button
                  type="button"
                  disabled={true}
                  className="flex w-full cursor-not-allowed items-center justify-center gap-2 rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 group-hover:blur"
                >
                  <svg
                    className="h-5 w-5 shrink-0"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
                      fill="white"
                    />
                  </svg>
                  Continue with GitHub
                </button>
                <div className="absolute inset-0 w-full h-full bg-opacity-60 backdrop-blur-md flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
                  <div className="flex z-10 cursor-pointer justify-center p-3 bg-green-200 rounded-xl flex-row space-x-3 items-center">
                    <span className="text-lg font-semibold text-gray-800 flex items-center">
                      Coming Soon
                    </span>
                    <svg
                      className="text-2xl justify-center items-center"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="relative flex w-full">
                <button
                  type="button"
                  onClick={() => Googlelogin()}
                  className="w-full  flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-[#FBF8F7] px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base"
                >
                  <svg
                    className="h-5 w-5 shrink-0"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.67 12.07c0-.76-.07-1.52-.19-2.26H12v4.29h5.91c-.26 1.4-1.05 2.58-2.23 3.37v2.82h3.58c2.1-1.95 3.41-4.82 3.41-8.22z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c3.1 0 5.68-1.02 7.57-2.76l-3.58-2.82c-.99.66-2.24 1.05-3.58 1.05-2.75 0-5.08-1.86-5.91-4.38H2.77v2.85C4.66 20.98 8.04 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M6.09 14.09a5.964 5.964 0 010-3.82V7.42H2.77a10.01 10.01 0 000 9.16l3.32-2.49z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 4.77c1.55 0 2.95.53 4.05 1.57l3.02-3.02C17.68 1.81 15.1.77 12 .77 8.04.77 4.66 2.79 2.77 5.97l3.32 2.49C6.92 6.63 9.25 4.77 12 4.77z"
                      fill="#EA4335"
                    />
                  </svg>
                  Continue with Google
                </button>

                {/* <button
                  type="button"
                  onClick={() => Googlelogin()}
                  className="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base"
                >
                  <svg
                    className="h-5 w-5 shrink-0"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7449 12.27C23.7449 11.48 23.6749 10.73 23.5549 10H12.2549V14.51H18.7249C18.4349 15.99 17.5849 17.24 16.3249 18.09V21.09H20.1849C22.4449 19 23.7449 15.92 23.7449 12.27Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12.2549 24C15.4949 24 18.2049 22.92 20.1849 21.09L16.3249 18.09C15.2449 18.81 13.8749 19.25 12.2549 19.25C9.12492 19.25 6.47492 17.14 5.52492 14.29H1.54492V17.38C3.51492 21.3 7.56492 24 12.2549 24Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.52488 14.09C5.27488 13.57 5.14488 12.8 5.14488 12C5.14488 11.2 5.28488 10.43 5.52488 9.71V6.62H1.54488C0.724882 8.24 0.254883 10.06 0.254883 12C0.254883 13.94 0.724882 15.76 1.54488 17.38L5.52488 14.29Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38C14.0249 5.38 15.6049 5.99 16.8549 6.55L20.2749 3.13C18.2049 1.19 15.4949 0 12.2549 0C7.56492 0 3.51492 2.7 1.54492 6.62L5.52492 9.71C6.47492 6.86 9.12492 5.38 12.2549 5.38Z"
                      fill="#EA4335"
                    />
                  </svg>
                  Continue with Google
                </button> */}
                {/* <div className="absolute inset-0 w-full h-full bg-opacity-60 backdrop-blur-md flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
                  <div className="flex z-10 cursor-pointer justify-center p-3 bg-green-200 rounded-xl flex-row space-x-3 items-center">
                    <span className="text-lg font-semibold text-gray-800 flex items-center">
                      Coming Soon
                    </span>
                    <svg
                      className="text-2xl justify-center items-center"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.67 12.07c0-.76-.07-1.52-.19-2.26H12v4.29h5.91c-.26 1.4-1.05 2.58-2.23 3.37v2.82h3.58c2.1-1.95 3.41-4.82 3.41-8.22z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c3.1 0 5.68-1.02 7.57-2.76l-3.58-2.82c-.99.66-2.24 1.05-3.58 1.05-2.75 0-5.08-1.86-5.91-4.38H2.77v2.85C4.66 20.98 8.04 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M6.09 14.09a5.964 5.964 0 010-3.82V7.42H2.77a10.01 10.01 0 000 9.16l3.32-2.49z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 4.77c1.55 0 2.95.53 4.05 1.57l3.02-3.02C17.68 1.81 15.1.77 12 .77 8.04.77 4.66 2.79 2.77 5.97l3.32 2.49C6.92 6.63 9.25 4.77 12 4.77z"
                        fill="#EA4335"
                      />
                    </svg>
                  </div>
                </div> */}
              </div>
            </div>
            <p className="text-sm flex justify-center mb-2 font-light text-gray-500 dark:text-gray-400">
              Already have an account yet?{" "}
              <Link
                to="/login"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpButton;
