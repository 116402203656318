import React, { useContext } from "react";
import img1 from "../../images/try1.png";
import img2 from "../../images/try2.png";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ThemeContext } from "../../ThemeContext"; // Assuming ThemeContext is located here

const Hero = () => {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext); // Get the current theme from context

  return (
    <section
      className={`body-font ${
        theme === "light" ? "text-gray-600" : "text-gray-200"
      }`}
    >
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1
            className={`title-font sm:text-4xl text-3xl mb-4 font-semibold ${
              theme === "light" ? "text-gray-900" : "text-gray-100"
            }`}
          >
            Get Your Resume
            <br className="hidden lg:inline-block" />
            Ready Right Away
          </h1>
          <p className="mb-8 leading-relaxed">
            Unlock Your Perfect Match: Upload your resume and job description to
            find the ideal fit at Match My Resume! 😊
          </p>
          {Cookies.get("token") ? (
            <div className="flex justify-center space-x-4">
              <button
                className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={() => navigate("/matchwithjd")}
              >
                Match Resume JD
              </button>
              <button
                className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={() => navigate("/resumeenhance")}
              >
                Enhance Resume
              </button>
            </div>
          ) : (
            <div className="flex justify-center">
              <button
                onClick={() => navigate("/matchwithjd")}
                className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Upload Resume
              </button>
            </div>
          )}
        </div>
        <div className="flex lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <Tilt
            className="tilt-container"
            tiltMaxAngleX={25}
            tiltMaxAngleY={25}
            perspective={1000}
            scale={1.05}
            transitionSpeed={400}
            glareEnable={false}
            glareMaxOpacity={0.8}
            glarePosition="bottom"
            glareBorderRadius="40px"
          >
            <img
              src={img2}
              alt="3D Tilt Image"
              className={`rounded-tl-xl rounded-bl-xl ${
                theme === "light" ? "bg-[#FBF8F7]" : "bg-gray-900"
              }`}
            />
          </Tilt>
          <Tilt
            className="tilt-container"
            tiltMaxAngleX={25}
            tiltMaxAngleY={25}
            perspective={1000}
            scale={1.05}
            transitionSpeed={400}
            glareEnable={false}
            glareMaxOpacity={0.8}
            glarePosition="bottom"
            glareBorderRadius="40px"
          >
            <img
              src={img1}
              alt="3D Tilt Image"
              className={`rounded-tr-xl mt-3 rounded-br-xl ${
                theme === "light" ? "bg-[#FBF8F7]" : "bg-gray-900"
              }`}
            />
          </Tilt>
        </div>
      </div>
    </section>
  );
};

export default Hero;
