import React, { useContext, useEffect, useRef, useState } from "react";
import { uploadFile } from "../../services/uploadService";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import axios from "axios";
import Cookies from "js-cookie";
import { fetchResumes } from "../../services/GetResumeService";
import pdfToText from "react-pdftotext";
import { ThemeContext } from "../../ThemeContext";

const ResumeUpload = ({ setResumeData }) => {
  const API_URL = "http://localhost:8080";
  const inputRef = useRef();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [recentResumes, setRecentResumes] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [showResumes, setShowResumes] = useState(false); // State to show/hide recent resumes
  const [selectedResumeName, setSelectedResumeName] = useState("");
  useEffect(() => {
    fetchRecentResumes();
  }, []);

  const fetchRecentResumes = async () => {
    try {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/signup");
        return;
      }
      try {
        const resumesData = await fetchResumes(Cookies.get("email"));
        setRecentResumes(resumesData);
      } catch (error) {
        console.error("Error fetching resumes:", error);
      }
    } catch (error) {
      console.error("Error fetching recent resumes:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 1024 * 1024;

    if (file && file.size > maxSize) {
      toast.warning("File size exceeds 1MB. Please upload a smaller file.", {
        position: "top-right",
      });

      event.target.value = null;
      setUploadStatus("fail");
    }

    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setUploadStatus("upload");
    }
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const handleResumeSelection = async (resumeId) => {
    const selectedResume = recentResumes.find(
      (data) => data.resume._id === resumeId
    );
    const pdfUrl = selectedResume.resume.s3Url;
    try {
      const response = await axios.get(pdfUrl, { responseType: "blob" });
      const pdfBlob = response.data;
      const file = new File([pdfBlob], "downloaded.pdf", {
        type: "application/pdf",
      });
      pdfToText(file)
        .then((text) => {
          setResumeData(text);
          setSelectedResumeName(selectedResume.resume.originalName);
          setShowResumes(!showResumes);
          toast.success("Text copied from resume!");
        })
        .catch((error) =>
          console.error("Failed to extract text from PDF", error)
        );
    } catch (error) {
      console.error("Error downloading or extracting PDF", error);
    }
  };

  const handleUpload = async () => {
    if (uploadStatus === "done") {
      clearFileInput();
      return;
    }
    try {
      setUploadStatus("uploading");
      const token = Cookies.get("token");
      if (!token) {
        navigate("/signup");
        return;
      }

      const response = await uploadFile(
        selectedFile,
        Cookies.get("token"),
        setProgress
      );
      if (response.limit) {
        toast.warning(
          "You have reached the maximum limit of 5 resumes. Please delete a previous resume to upload a new one.",
          {
            position: "top-right",
          }
        );
        setUploadStatus("fail");
        return;
      }
      pdfToText(selectedFile)
        .then((text) => setResumeData(text))
        .catch((error) =>
          console.error("Failed to extract text from PDF", error)
        );

      setUploadStatus("done");
    } catch (error) {
      throw new Error(error.message || "Failed to upload file.");
    }
  };

  const clearFileInput = () => {
    inputRef.current.value = null;
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const { theme } = useContext(ThemeContext);

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {uploadStatus === "uploading" && <Loading work="uploading" />}
      <h1
        className={`text-2xl font-semibold mb-4 ${
          theme === "light" ? "text-gray-900" : "text-gray-100"
        }`}
      >
        Upload Your Resume Here
      </h1>
      {!selectedFile && (
        <button
          className={`w-[330px] h-[150px] text-2xl font-semibold flex flex-col items-center justify-center gap-3 border-2 border-dashed rounded-2xl cursor-pointer ${
            theme === "light"
              ? "border-[#5d4dcc] text-[#5d4dcc] hover:text-[#7d6ee3]"
              : "border-gray-500 text-gray-400 hover:text-white"
          } expand`}
          onClick={onChooseFile}
        >
          <span
            className={`w-[50px] h-[50px] text-xl flex pt-2 rounded-full ${
              theme === "light"
                ? "text-[#5d4dcc] hover:bg-[#b2a6fd] hover:text-[#7d6ee3]"
                : "text-gray-400 hover:bg-gray-600 hover:text-white"
            } material-symbols-outlined`}
          >
            Upload
          </span>
          Upload File
        </button>
      )}
      <button
        className={`w-[330px] text-lg font-semibold py-2 px-4 rounded-lg cursor-pointer ${
          recentResumes.length > 0
            ? theme === "light"
              ? "bg-[#5d4dcc] text-white hover:bg-[#7d6ee3]"
              : "bg-gray-700 text-white hover:bg-gray-600"
            : "bg-gray-400 text-gray-300 cursor-not-allowed"
        }`}
        onClick={() => setShowResumes(!showResumes)}
        disabled={recentResumes.length === 0}
      >
        {showResumes
          ? "Hide Recent Resumes"
          : selectedResumeName || "Use Recent Resumes"}
      </button>

      {recentResumes.length > 0 && (
        <div className="w-full relative">
          {showResumes && (
            <div
              className={`w-[330px] text-lg border p-2 rounded-lg ${
                theme === "light" ? "bg-[#FAF8F7]" : "bg-gray-800"
              } absolute z-10 mt-2`}
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {recentResumes.map((data) => (
                <div
                  key={data.resume._id}
                  className={`flex justify-between items-center mb-2 p-2 border rounded-lg ${
                    theme === "light"
                      ? "hover:bg-gray-100"
                      : "hover:bg-gray-600"
                  }`}
                >
                  <span
                    className={`${
                      theme === "light" ? "text-black" : "text-gray-200"
                    }`}
                  >
                    {data.resume.originalName} -{" "}
                    {new Date(data.resume.uploadedAt).toLocaleDateString()}
                  </span>
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600"
                    onClick={() => handleResumeSelection(data.resume._id)}
                  >
                    USE
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {selectedFile && (
        <div className="flex flex-col w-[330px]">
          <div
            className={`flex items-center gap-[15px] ${
              theme === "light"
                ? "text-black bg-white"
                : "text-gray-200 bg-gray-800"
            } border-2 border-solid ${
              theme === "light" ? "border-[#636c79]" : "border-gray-600"
            } rounded-2xl px-4 py-8`}
          >
            <span
              className={`material-symbols-outlined text-base ${
                theme === "light" ? "text-[#7460ff]" : "text-[#b2a6fd]"
              }`}
            >
              description
            </span>

            <div className="flex-1 flex items-center gap-5">
              <div style={{ flex: 1 }}>
                <h6 className="flex-1 text-sm font-semibold">
                  {selectedFile.name}
                </h6>
                <div className="progress-bg w-[100%] h-5px bg-slate-400 rounded-2xl mt-2 flex items-center">
                  <div
                    className={`progress h-1 ${
                      theme === "light" ? "bg-[#5d4dcc]" : "bg-[#b2a6fd]"
                    } rounded-2xl transition-all duration-500`}
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <span className="ml-2 text-sm text-gray-700">{progress}%</span>
              </div>
              <button
                className="w-[36px] h-[36px] flex items-center justify-center text-lg text-white bg-[#7460ff] hover:bg-[#b2a6fd] hover:opacity-70 border-none rounded-full"
                onClick={clearFileInput}
              >
                <span className="material-symbols-outlined text-lg cursor-pointer">
                  Close
                </span>
              </button>
            </div>
          </div>

          <button
            className={`w-full text-lg font-semibold text-white rounded-lg p-3 mt-4 cursor-pointer ${
              theme === "light"
                ? "bg-[#5d4dcc] hover:bg-[#7d6ee3]"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
            onClick={handleUpload}
            disabled={uploadStatus === "uploading"}
          >
            {uploadStatus === "uploading"
              ? "Uploading..."
              : uploadStatus === "done"
              ? "Change Resume"
              : "Upload"}
          </button>
        </div>
      )}

      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".pdf"
      />
    </div>
  );
};

export default ResumeUpload;
