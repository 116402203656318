import React, { useState, useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { FaAngleDown } from "react-icons/fa6";
const FaqSection = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <section className="relative z-20 overflow-hidden pb-12 pt-20 lg:pb-[90px] lg:pt-[120px]">
      <div
        className={`container mx-auto ${
          theme === "dark"
            ? "bg-dark-2 shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] text-white"
            : "bg-[#FBF8F7] text-dark"
        }`}
      >
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                FAQ
              </span>
              <h2 className="mb-4 text-3xl font-bold sm:text-[40px]/[48px]">
                Any Questions? Look Here
              </h2>
              <p className="text-base">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header="How does MatchMyResume work?"
              text="MatchMyResume analyzes your resume and compares it with the job description to generate a match score, highlighting relevant skills and providing suggestions to improve your resume for a better fit."
            />
            <AccordionItem
              header="Is my personal data secure on MatchMyResume?"
              text="Yes, we prioritize data security. Your information is encrypted, and we adhere to strict data privacy protocols to ensure that your resume and details are safe."
            />
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header="Can I use MatchMyResume to apply for multiple jobs?"
              text="Absolutely! You can use MatchMyResume as many times as you need to match your resume with different job descriptions and tailor it for multiple applications."
            />
            <AccordionItem
              header="What if I don't get a high match score?"
              text="No worries! We provide AI-driven suggestions to help you improve your resume and increase your match score, making your application more competitive."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;

const AccordionItem = ({ header, text }) => {
  const { theme } = useContext(ThemeContext);
  const [active, setActive] = useState(false);

  const handleToggle = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  return (
    <div
      className={`mb-8 w-full rounded-lg p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] sm:p-8 lg:px-6 xl:px-8 ${
        theme === "dark"
          ? "bg-dark-2 shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] text-white"
          : "bg-white text-dark"
      }`}
    >
      <button className="faq-btn flex w-full text-left" onClick={handleToggle}>
        <div
          className={`mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg ${
            theme === "dark" ? "bg-white/5" : "bg-primary/5"
          }`}
        >
          <FaAngleDown
            className={`transition-transform duration-200 ease-in-out ${
              active ? "-rotate-180" : ""
            } ${theme === "dark" ? "text-white" : "text-black"}`}
          />
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-semibold">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-3 text-base leading-relaxed">{text}</p>
      </div>
    </div>
  );
};
