import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import img1 from "../../images/Designer6.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "../../ThemeContext";
const BuyCredits = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const initPayment = (data) => {
    const options = {
      key: "rzp_live_Wjb3UJf0LNInG0",
      // amount: 1,
      amount: data.amount,
      currency: data.currency,
      name: "MatchMyResume",
      description: "Test Transaction",
      image: img1,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl =
            "https://matchmyresumebackend.vercel.app/api/payOrder/payment-verification";
          const email = Cookies.get("email");
          const postData = {
            response,
            email,
            plan: data.notes.plan,
          };

          const result = await axios.post(verifyUrl, postData);
          console.log(result.data);
          navigate("/payment-success");
        } catch (error) {
          console.log(error);
          navigate("/payment-failed");
        }
      },
      prefill: {
        name: "Abhinay ",
        email: "abhiapple@gmail.com",
        contact: "6304387583",
      },
      theme: {
        color: "#938bff",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    if (!selectedPlan) {
      toast.error(`Please select a plan`, {
        position: "top-right",
      });
      return;
    }
    if (!Cookies.get("token")) {
      navigate("/login");
    } else {
      try {
        if (selectedPlan) {
          const amount = selectedPlan === "Student" ? 32 : 70;
          const receipt = `receipt_${selectedPlan}`;

          const orderUrl =
            "https://matchmyresumebackend.vercel.app/api/payOrder/create-order";
          const { data } = await axios.post(orderUrl, {
            // amount: 1,
            amount: amount,
            currency: "INR",
            receipt: receipt,
            plan: selectedPlan,
          });
          console.log(data, "from new code");
          initPayment(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`${theme === "light" ? "text-gray-600" : "text-gray-100"}`}>
      <div className="bg-[#b9b4ff]"></div>
      <section className="  body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 ">
              Pricing
            </h1>
          </div>
          <div
            className={`lg:w-2/3 w-full mx-auto overflow-auto  ${
              theme === "light" ? "text-gray-800 " : "text-gray-100"
            }`}
          >
            <table
              className={`table-auto w-full text-left whitespace-no-wrap  `}
            >
              <thead
                className={`${
                  theme === "light" ? "bg-gray-100 " : "bg-gray-800"
                }`}
              >
                <tr>
                  <th className="px-4 py-3 title-font tracking-wider font-medium  text-sm  rounded-tl rounded-bl">
                    Plan
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium  text-sm ">
                    Matching Credits
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium  text-sm ">
                    Ai Credits
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium  text-sm ">
                    Price
                  </th>
                  <th className="w-10 title-font tracking-wider font-medium  text-sm  rounded-tr rounded-br" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-3">Base</td>
                  <td className="px-4 py-3">3</td>
                  <td className="px-4 py-3">2</td>
                  <td className="px-4 py-3 text-lg ">Free</td>
                  <td className="w-10 cursor-not-allowed text-center">
                    <input disabled name="plan" type="radio" />
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Single
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    +<span className="font-bold">10</span>
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    +<span className="font-bold">5</span>
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3 text-lg ">
                    <div className="flex items-center">
                      <span className="line-through text-red-500 mr-2">
                        ₹49
                      </span>
                      <span className="relative text-green-600 font-bold">
                        ₹14
                      </span>
                      <span className="text-sm text-green-500 ml-2">
                        (70% off)
                      </span>
                      <span className="sparkle ml-2">✨</span>
                    </div>
                  </td>
                  <td className="border-t-2 border-gray-200 w-10 text-center">
                    <input
                      name="plan"
                      type="radio"
                      onChange={() => setSelectedPlan("Single")}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Committed
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    +<span className="font-bold">20</span>
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    +<span className="font-bold">10</span>
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3 text-lg ">
                    <div className="flex items-center">
                      <span className="line-through text-red-500 mr-2">
                        ₹99
                      </span>
                      <span className="relative text-green-600 font-bold">
                        ₹19
                      </span>
                      <span className="text-sm text-green-500 ml-2">
                        (80% off)
                      </span>
                      <span className="sparkle ml-2">✨</span>
                    </div>
                  </td>
                  <td className="border-t-2 border-gray-200 w-10 text-center">
                    <input
                      name="plan"
                      type="radio"
                      onChange={() => setSelectedPlan("Committed")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Business
                  </td>
                  <td
                    colSpan="3"
                    className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-lg  text-center"
                  >
                    Send an Email at{" "}
                    <a href="mailto:abhiapplee@gmail.com">
                      abhiapplee@gmail.com
                    </a>
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 w-10 text-center">
                    <input disabled name="plan" type="radio" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex items-center pl-4 mt-4 lg:w-2/3 w-full mx-auto">
            <input
              id="terms-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="terms-checkbox" className="cursor-pointer">
              I agree to the{" "}
              <a onClick={openModal} className="text-indigo-500 cursor-pointer">
                Terms & Conditions
              </a>
            </label>

            <button
              onClick={handlePayment}
              disabled={!isChecked}
              className={`flex ml-auto text-white border-0 py-2 px-6 focus:outline-none rounded ${
                isChecked
                  ? "bg-indigo-500 hover:bg-indigo-600 cursor-pointer"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              Pay Now
            </button>
          </div>
        </div>
      </section>

      {showModal && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50 `}
        >
          <div
            className={` rounded-lg  ${
              theme === "light" ? "bg-white " : "bg-black"
            } w-1/3 mx-auto p-6 relative`}
          >
            <h2 className="text-2xl font-semibold mb-4">
              Terms and Conditions
            </h2>

            <div className="mb-4 overflow-y-auto max-h-96">
              <p className="mb-4">
                Welcome to MatchMyResume. By using our services, you agree to
                the following terms and conditions. Please read them carefully.
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                1. Acceptance of Terms
              </h3>
              <p className="mb-4">
                By accessing or using our services, you agree to comply with and
                be bound by these Terms and Conditions. If you do not agree with
                any part of the terms, you may not access our services.
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                2. Payment Policy
              </h3>
              <p className="mb-4">
                All payments made through MatchMyResume are processed securely.
                You agree to provide accurate and complete payment information
                and authorize us to charge your payment method for the services
                requested.
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>Prices listed on our site are final and non-negotiable.</li>
                <li>Payments must be made in full at the time of purchase.</li>
                <li>
                  We accept payments through major credit cards and other secure
                  payment gateways.
                </li>
              </ul>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                3. Privacy Policy
              </h3>
              <p className="mb-4">
                Your privacy is important to us. We are committed to protecting
                your personal information and respecting your privacy. Any
                personal information you provide through our website will be
                used solely for the purpose of providing our services. We
                collect and use your information in accordance with our{" "}
                <a href="#" className="text-blue-600 underline">
                  Privacy Policy
                </a>
                .
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                4. Refund Policy
              </h3>
              <p className="mb-4">
                At this time, all sales made through MatchMyResume are{" "}
                <strong>final</strong>. We do not offer any refunds for our
                services once payment has been made. This includes but is not
                limited to the purchase of matching credits, AI services, or
                other paid features.
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                5. Modifications to Service
              </h3>
              <p className="mb-4">
                We reserve the right to modify or discontinue, temporarily or
                permanently, any part of our services at any time with or
                without notice. We shall not be liable to you or any third party
                for any modification, price change, suspension, or
                discontinuance of service.
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">6. Liability</h3>
              <p className="mb-4">
                MatchMyResume is not responsible for any indirect, incidental,
                or consequential damages resulting from the use of our services.
                We do not guarantee success in matching your resume with jobs or
                job offers.
              </p>

              <h3 className="text-lg font-semibold mt-4 mb-2">
                7. Governing Law
              </h3>
              <p className="mb-4">
                These terms and conditions are governed by and construed in
                accordance with the laws of [Your Jurisdiction]. You agree to
                submit to the exclusive jurisdiction of the courts located in
                [Your Jurisdiction] for the resolution of any disputes arising
                from or related to these terms.
              </p>
            </div>

            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              X
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default BuyCredits;
