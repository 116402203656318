import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import FormSubmitted from "../components/contactus/FormSubmitted";
import QueryForm from "../components/contactus/QueryForm";

const QueryFormPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const cookieValue = Cookies.get("formSubmitted") === "true";
    setFormSubmitted(cookieValue);
  }, []);

  const handleFormSubmit = () => {
    setFormSubmitted(true);
    Cookies.set("formSubmitted", "true", { expires: 365 });
  };

  return (
    <div>
      {formSubmitted ? (
        <FormSubmitted />
      ) : (
        <QueryForm onFormSubmit={handleFormSubmit} />
      )}
    </div>
  );
};

export default QueryFormPage;
