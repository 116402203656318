import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { FaMoon, FaSun, FaBars, FaTimes } from "react-icons/fa";
import { ThemeContext } from "../ThemeContext";
import { FaRegCircleUser } from "react-icons/fa6";
import img1 from "../images/Designer6.png";

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext); // Access theme and toggle function
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // New state for mobile menu
  const userButtonRef = useRef(null);
  const buttonWidth = userButtonRef.current?.offsetWidth || 0;
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    Cookies.remove("userName");
    window.location.href = "/login"; // Redirect to login page
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle mobile menu
  };

  return (
    <header
      className={`body-font transition-colors duration-300 ${
        theme === "light" ? "text-gray-600" : "text-gray-100"
      }`}
    >
      <div className="container w-full flex justify-between pt-7 px-2 items-center">
        <Link
          to="/"
          className={`flex title-font font-medium items-center ${
            theme === "light" ? "text-gray-900" : "text-gray-100"
          }`}
        >
          <img
            src={img1}
            alt="MatchMyResume Logo"
            className={`w-8 h-8 ${theme === "dark" && "bg-gray-900"}`}
          />
          <span className="ml-3 font-semibold text-xl">MatchMyResume</span>
        </Link>
        <button
          onClick={toggleMenu}
          className="md:hidden p-2 rounded focus:outline-none transition-transform duration-300"
        >
          {menuOpen ? (
            <FaTimes className="text-2xl" />
          ) : (
            <FaBars className="text-2xl" />
          )}
        </button>

        {/* Desktop Menu */}
        <nav className="hidden title-font font-medium items-center md:flex space-x-4">
          {/* Desktop Links */}
          <Link to="/makeresume" className="hover:text-green-400">
            Make Resume
          </Link>
          <Link to="/matchwithjd" className="hover:text-green-400">
            Resume Matcher
          </Link>
          <Link to="/resumeenhance" className="hover:text-green-400">
            Resume Enhancer
          </Link>
          <Link to="/resumehistory" className="hover:text-green-400">
            History
          </Link>
          <Link to="/job-apply" className="relative hover:text-green-400">
            Jobs
            <span className="top-0 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-[1px] ml-1">
              NEW
            </span>
          </Link>
          <Link to="/buycredits" className="hover:text-green-400">
            Buy Credits ⚡️
          </Link>
          <Link to="/contactus" className="hover:text-green-400">
            Contact Us
          </Link>
          <button onClick={toggleTheme} className="p-2 rounded-full">
            {theme === "light" ? <FaMoon /> : <FaSun />}
          </button>
          {Cookies.get("userName") ? (
            <div className="relative inline-block text-left">
              <button
                ref={userButtonRef}
                onClick={handleDropdownToggle}
                className={`inline-flex items-center ml-4 ${
                  theme === "light"
                    ? "bg-gray-100 hover:bg-gray-200 text-gray-900"
                    : "bg-gray-700 hover:bg-gray-800 text-gray-100"
                } border-0 py-2 px-3 focus:outline-none rounded-2xl text-base mt-4 md:mt-0`}
              >
                <FaRegCircleUser className="mr-2" />
                {Cookies.get("userName")?.split(" ")[0]}{" "}
              </button>

              {dropdownOpen && (
                <div
                  className={`origin-top-right absolute right-0 mt-2 rounded-xl shadow-lg ${
                    theme === "light"
                      ? "bg-white text-red-700 hover:bg-red-100 hover:text-red-900"
                      : "bg-gray-800 text-red-400 hover:bg-red-500 hover:bg-opacity-20 hover:text-red-300"
                  } ring-1 ring-black ring-opacity-5`}
                  style={{ width: buttonWidth }}
                >
                  <div className="py-1">
                    <button
                      onClick={handleLogout}
                      className={`block w-full z-10 text-left px-4 py-2 text-sm rounded-xl transition duration-200 `}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link
              to="/signup"
              className={`inline-block mx-2 py-2 px-3 text-center text-sm rounded-2xl font-semibold outline-none transition duration-100 ${
                theme === "light"
                  ? "bg-gray-200 text-gray-500 hover:bg-green-200"
                  : "bg-gray-700 text-gray-100 hover:bg-green-400"
              }`}
            >
              Sign Up
            </Link>
          )}
        </nav>

        {menuOpen && (
          <div
            className={`md:hidden absolute top-16 left-0 w-full bg-gray-800 text-white shadow-lg z-50 rounded-md mt-2 transition-all duration-500 ease-in-out transform ${
              menuOpen ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
            }`}
            style={{ transformOrigin: "top" }}
          >
            <nav className="flex flex-col p-4 space-y-2">
              <Link
                to="/makeresume"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Make Resume
              </Link>
              <Link
                to="/matchwithjd"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Resume Matcher
              </Link>
              <Link
                to="/resumeenhance"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Resume Enhancer
              </Link>
              <Link
                to="/resumehistory"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                History
              </Link>
              <Link
                to="/job-apply"
                className="relative hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Jobs
                <span className="bg-red-500 text-white text-xs font-bold rounded-full px-2 ml-1">
                  NEW
                </span>
              </Link>
              <Link
                to="/buycredits"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Buy Credits ⚡️
              </Link>
              <Link
                to="/contactus"
                className="hover:bg-gray-700 p-2 rounded"
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
              <button onClick={toggleTheme} className="p-2 rounded-full">
                {theme === "light" ? <FaMoon /> : <FaSun />}
              </button>
              {Cookies.get("userName") ? (
                <button
                  onClick={() => {
                    handleLogout();
                    toggleMenu(); // Close menu after logout
                  }}
                  className="text-left p-2 hover:bg-red-600 rounded"
                >
                  Logout
                </button>
              ) : (
                <Link
                  to="/signup"
                  className="p-2 hover:bg-green-500 rounded"
                  onClick={toggleMenu}
                >
                  Sign Up
                </Link>
              )}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
