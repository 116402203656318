import React, { useContext, useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { deleteResume } from "../../services/GetResumeService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from "../../images/resume1.png";
import Loading from "../Loading";
import { ThemeContext } from "../../ThemeContext";
const ResumeCard = ({ link, name, time, setResumes, id }) => {
  const formattedDate = new Date(time).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const [loading, setloading] = useState(false);
  const handleDelete = async (id) => {
    setloading(true);
    try {
      await deleteResume(id);
      setResumes((prevResumes) =>
        prevResumes.filter((resume) => resume._id !== id)
      );
      setloading(false);
    } catch (error) {
      console.error("Failed to delete resume:", error);
      setloading(false);
    }
  };
  useEffect(() => {
    if (loading) {
      toast.error("Resume Deleted successfully", {
        position: "top-right",
      });
    }
  }, [loading]);

  const { theme } = useContext(ThemeContext);

  return (
    <div className="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
      {loading && <Loading work="Deleting" />}
      <div
        className="group relative block shrink-0 self-start overflow-hidden rounded-lg shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
        style={{ backgroundColor: theme === "light" ? "#f3f4f6" : "#2d3748" }}
      >
        <img
          src={img}
          loading="lazy"
          alt="Resume Image"
          className="w-80 object-fill border-none"
        />
      </div>

      <div className="flex flex-col gap-2">
        <span
          className={`text-sm ${
            theme === "light" ? "text-gray-400" : "text-gray-500"
          }`}
        >
          {formattedDate}
        </span>
        <h2
          className={`text-xl font-bold ${
            theme === "light" ? "text-gray-800" : "text-gray-100"
          }`}
        >
          <a
            href={link}
            className={`transition duration-100 hover:text-indigo-500 active:text-indigo-600 ${
              theme === "dark" && "hover:text-indigo-400 active:text-indigo-500"
            }`}
          >
            {name}
          </a>
        </h2>

        <div className="flex flex-row space-x-3">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex flex-row w-20 cursor-pointer justify-center items-center space-x-1 font-semibold py-1 px-2 border-2 rounded ${
              theme === "light"
                ? "bg-blue-200 text-blue-700 hover:bg-blue-500 hover:text-white border-blue-200"
                : "bg-transparent text-blue-400 hover:bg-blue-500 hover:text-white border-blue-400"
            }`}
          >
            <span>View</span>
            <FiEye />
          </a>
          <button
            onClick={() => handleDelete(id)}
            className={`flex flex-row w-20 justify-center items-center space-x-1 font-semibold py-1 px-1 border-2 rounded ${
              theme === "light"
                ? "bg-red-200 text-red-700 hover:bg-red-500 hover:text-white border-red-200"
                : "bg-transparent text-red-400 hover:bg-red-500 hover:text-white border-red-400"
            }`}
          >
            <span>Delete</span>
            <MdDeleteOutline />
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResumeCard;
