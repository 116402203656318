import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SingleSkill = ({ name, skillsDo }) => {
  const handleCopy = () => {
    toast.success(`${name} copied to clipboard`, {
      position: "top-right",
    });
    navigator.clipboard
      .writeText(name)
      .then(() => {
        console.log(`Copied to clipboard: ${name}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="p-2">
      <div
        className={`inline-flex items-center border-gray-200 border  p-1 rounded-lg ${
          skillsDo === "having"
            ? "bg-green-200"
            : "bg-[#F9FAFB] cursor-pointer hover:bg-green-100"
        }`}
        onClick={skillsDo !== "having" ? handleCopy : undefined}
      >
        {skillsDo === "having" ? (
          <FaRegCircleCheck className="w-4 h-4 text-green-80000 font-bold object-cover object-center flex-shrink-0 rounded-full mr-2" />
        ) : (
          <FaRegCopy className={`w-4 h-4  object-cover object-center mr-2 `} />
        )}
        <div className="flex-grow min-w-0">
          <p
            className={`text-gray-500 whitespace-nowrap ${
              skillsDo !== "having" && "hover:text-green-400"
            }`}
          >
            {name}
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SingleSkill;
