import React, { useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";

const ResumeBuilder = () => {
  const [formData, setFormData] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "+1 (123) 456-7890",
    linkedIn: "https://linkedin.com/in/johndoe",
    github: "https://github.com/johndoe",
    portfolio: "https://johndoe.dev",
    extra: [
      {
        heading: "Achievements",
        points: [
          "Awarded Employee of the Month at ABC Corp",
          "Completed a marathon in under 4 hours",
          "Fluent in Spanish and French",
        ],
      },
    ],
    skills: [
      {
        technical: ["JavaScript", "React", "Node.js", "Express", "MongoDB"],
        softSkills: ["Leadership", "Time Management", "Communication"],
        tools: ["Git", "Docker", "Figma", "JIRA"],
      },
    ],
    experience: [
      {
        role: "Software Engineer",
        company: "ABC Corp",
        technologies: ["React", "Node.js", "AWS"],
        points: [
          "Developed and maintained web applications with a focus on performance and usability",
          "Collaborated with cross-functional teams to design, develop, and deploy new features",
          "Optimized application performance, reducing load time by 20%",
        ],
        date: "Jan 2021 - Present",
      },
    ],
    projects: [
      {
        name: "Portfolio Website",
        technologies: ["HTML", "CSS", "JavaScript", "React"],
        points: [
          "Built a personal portfolio website to showcase projects and skills",
          "Implemented responsive design for optimal viewing on various devices",
          "Integrated GitHub API to display latest repositories",
        ],
        date: "Aug 2022",
        projectLink: "https://johndoe.dev",
      },
    ],
    education: [
      {
        institution: "University of Technology",
        degree: "Bachelor of Science in Computer Science",
        startYear: "2017",
        endYear: "2021",
        marks: "3.8 GPA",
      },
    ],
  });

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("resumeData"));
    if (savedData) {
      setFormData(savedData);
    }
  }, []);

  // Save formData to localStorage on change
  useEffect(() => {
    localStorage.setItem("resumeData", JSON.stringify(formData));
    console.log(formData, "from 318");
  }, [formData]);

  // Generic handler for form inputs
  const handleInputChange = (e, section, index, field) => {
    if (index !== undefined) {
      setFormData((prevState) => ({
        ...prevState,
        [section]: prevState[section].map((item, i) =>
          i === index ? { ...item, [field]: e.target.value } : item
        ),
      }));
    } else {
      setFormData({ ...formData, [section]: e.target.value });
    }
  };

  // Handle points change for experience and projects
  const handlePointsChange = (e, section, idx, pointIdx) => {
    const updatedSection = formData[section].map((item, i) => {
      if (i === idx) {
        const updatedPoints = item.points.map((point, pIdx) =>
          pIdx === pointIdx ? e.target.value : point
        );
        return { ...item, points: updatedPoints };
      }
      return item;
    });
    setFormData({ ...formData, [section]: updatedSection });
  };

  const addSectionItem = (section) => {
    const emptyItem =
      section === "experience"
        ? { role: "", company: "", technologies: [""], points: [""], date: "" }
        : section === "projects"
        ? {
            name: "",
            technologies: [""],
            points: [""],
            date: "",
            projectLink: "",
          }
        : section === "education"
        ? {
            institution: "",
            degree: "",
            startYear: "",
            endYear: "",
            marks: "",
          }
        : null;

    if (emptyItem) {
      setFormData((prevState) => ({
        ...prevState,
        [section]: [...prevState[section], emptyItem],
      }));
    }
  };

  const addPoint = (section, idx) => {
    const updatedSection = formData[section].map((item, i) => {
      if (i === idx) {
        return { ...item, points: [...item.points, ""] };
      }
      return item;
    });
    setFormData({ ...formData, [section]: updatedSection });
  };
  const addSkill = () => {
    setFormData((prevState) => ({
      ...prevState,
      skills: [...prevState.skills, ""],
    }));
  };

  // Delete experience, project, or point
  const deleteSectionItem = (section, idx) => {
    setFormData((prevState) => ({
      ...prevState,
      [section]: prevState[section].filter((_, i) => i !== idx),
    }));
  };

  const deletePoint = (section, idx, pointIdx) => {
    const updatedSection = formData[section].map((item, i) => {
      if (i === idx) {
        return {
          ...item,
          points: item.points.filter((_, pIdx) => pIdx !== pointIdx),
        };
      }
      return item;
    });
    setFormData({ ...formData, [section]: updatedSection });
  };

  const handleSkillInputChange = (e, skillType) => {
    setFormData((prevState) => ({
      ...prevState,
      skills: {
        ...prevState.skills,
        [skillType]: e.target.value, // Update the string directly
      },
    }));
  };

  return (
    <div className="flex flex-col md:grid md:grid-cols-2 gap-6 p-6 bg-gray-100 h-auto md:h-[100vh]">
      {/* Form Section */}
      <div className="space-y-6 bg-white p-6 h-auto md:h-[90vh] overflow-y-scroll rounded shadow-md">
        <SectionHeading title="Personal Information" />
        {["name", "email", "phone", "linkedIn", "github", "portfolio"].map(
          (field) => (
            <InputField
              key={field}
              placeholder={capitalize(field.replace(/([A-Z])/g, " $1"))}
              value={formData[field]}
              onChange={(e) => handleInputChange(e, field)}
            />
          )
        )}

        <SectionHeading title="Skills" />

        {["technical", "softSkills", "tools"].map((skillType) => (
          <div key={skillType} className="mt-4">
            <InputField
              placeholder={`Enter ${capitalize(
                skillType
              )} skills separated by commas`}
              value={formData.skills[skillType]}
              onChange={(e) => handleSkillInputChange(e, skillType)}
            />
          </div>
        ))}

        <SectionHeading title="Education" />
        {formData.education.map((exp, idx) => (
          <ExperienceProjectForm
            key={idx}
            section="education"
            data={exp}
            idx={idx}
            onChange={handleInputChange}
            handlePointsChange={handlePointsChange}
            addPoint={addPoint}
            deleteSectionItem={deleteSectionItem}
            deletePoint={deletePoint}
          />
        ))}
        <Button
          onClick={() => addSectionItem("education")}
          label="Add Education"
        />

        <SectionHeading title="Work Experience" />
        {formData.experience.map((exp, idx) => (
          <ExperienceProjectForm
            key={idx}
            section="experience"
            data={exp}
            idx={idx}
            onChange={handleInputChange}
            handlePointsChange={handlePointsChange}
            addPoint={addPoint}
            deleteSectionItem={deleteSectionItem}
            deletePoint={deletePoint}
          />
        ))}
        <Button
          onClick={() => addSectionItem("experience")}
          label="Add Experience"
        />

        <SectionHeading title="Projects" />
        {formData.projects.map((proj, idx) => (
          <ExperienceProjectForm
            key={idx}
            section="projects"
            data={proj}
            idx={idx}
            onChange={handleInputChange}
            handlePointsChange={handlePointsChange}
            addPoint={addPoint}
            deleteSectionItem={deleteSectionItem}
            deletePoint={deletePoint}
          />
        ))}
        <Button
          onClick={() => addSectionItem("projects")}
          label="Add Project"
        />

        <SectionHeading title="Custom Section" />
        {formData.extra.map((data, idx) => (
          <ExperienceProjectForm
            key={idx}
            section="extra"
            data={data}
            idx={idx}
            onChange={handleInputChange}
            handlePointsChange={handlePointsChange}
            addPoint={addPoint}
            deleteSectionItem={deleteSectionItem}
            deletePoint={deletePoint}
          />
        ))}
      </div>

      {/* Resume Preview Section */}
      <div className="bg-white p-6 h-auto md:h-[90vh] overflow-y-scroll rounded shadow-md mt-6 md:mt-0">
        <ResumePreview formData={formData} />
      </div>
    </div>
  );
};

// Resume Preview Component
function ResumePreview({ formData }) {
  const componentRef = React.useRef(null);
  const handleOnAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Resume",
    onAfterPrint: handleOnAfterPrint,
  });

  const handlePrint = React.useCallback(() => {
    printFn();
  }, [printFn]);

  return (
    <div>
      <button
        onClick={handlePrint}
        className="bg-green-500 text-white px-4 py-2 rounded mt-4"
      >
        Print Resume
      </button>
      <div ref={componentRef}>
        <ResumeContent formData={formData} />
      </div>
    </div>
  );
}

function ResumeContent({ formData }) {
  return (
    <div className="p-10">
      {/* Name and Contact Information */}
      <h1 className="text-2xl flex justify-center  font-bold">
        {formData.name}
      </h1>
      <p className="text-xs flex justify-center ">
        {formData.email} | {formData.phone}
        {formData.linkedIn && (
          <>
            |
            <ConditionalLink label="LinkedIn" url={formData.linkedIn} />
          </>
        )}
        {formData.github && (
          <>
            |
            <ConditionalLink label="GitHub" url={formData.github} />
          </>
        )}
        {formData.portfolio && (
          <>
            |
            <ConditionalLink label="Portfolio" url={formData.portfolio} />
          </>
        )}
      </p>

      <SectionHeading title="Skills" />

      <div className="mt-0.5 leading-tight">
        <h3 className="font-bold text-xs inline">Technical Skills:</h3>
        <span className="font-normal text-xs inline">
          {" "}
          {formData.skills.technical}
        </span>
      </div>

      <div className="mt-0.5 leading-tight">
        <h3 className="font-bold text-xs inline">Soft Skills:</h3>
        <span className="font-normal text-xs inline">
          {" "}
          {formData.skills.softSkills}
        </span>
      </div>

      <div className="mt-0.5 leading-tight">
        <h3 className="font-bold text-xs inline">Tools:</h3>
        <span className="font-normal text-xs inline">
          {" "}
          {formData.skills.tools}
        </span>
      </div>

      {/* Education Section */}
      <SectionHeading title="Education" />
      {formData?.education?.map((edu, idx) => (
        <div key={idx} className="mt-2">
          <h3 className="font-bold flex justify-between">
            <span>{edu.institution}</span>
            <span className="text-xs">
              {edu.startYear} - {edu.endYear}
            </span>
          </h3>
          <div className="flex justify-between">
            <p className="text-xs">{edu.degree}</p>
            <p className="text-xs"> {edu.marks} GPA</p>
          </div>
        </div>
      ))}

      {/* Work Experience Section */}
      <SectionHeading title="Work Experience" />
      {formData.experience.map((exp, idx) => (
        <div key={idx} className="mt-2">
          <h3 className=" flex font-bold justify-between">
            <span>
              {exp.role} - {exp.company} ({exp.date})
            </span>
            <span className="text-xs font-normal text-gray-600">
              {exp?.technologies}
            </span>
          </h3>
          <ul className="list-disc list-inside">
            {exp.points.map((point, pointIdx) => (
              <li key={pointIdx} className="text-xs">
                {point}
              </li>
            ))}
          </ul>
        </div>
      ))}

      {/* Projects Section */}
      <SectionHeading title="Projects" />
      {formData.projects.map((project, idx) => (
        <div key={idx} className="mt-2">
          <h3 className="font-bold flex justify-between">
            <span>
              {project.name} ({project.date})
              {project.projectLink && (
                <a
                  href={project.projectLink}
                  target="_blank"
                  className="underline font-normal ml-2 text-blue-700"
                >
                  Demo
                </a>
              )}
            </span>

            <span className="text-xs font-normal text-gray-600">
              {project?.technologies}
            </span>
          </h3>
          <ul className="list-disc list-inside">
            {project.points.map((point, pointIdx) => (
              <li key={pointIdx} className="text-xs">
                {point}
              </li>
            ))}
          </ul>
        </div>
      ))}

      {formData.extra && formData.extra[0]?.heading && (
        <>
          <SectionHeading title={formData.extra[0]?.heading} />
          <ul className="list-disc list-inside">
            {formData.extra[0]?.points.map((point, pointIdx) => (
              <li key={pointIdx} className="text-xs">
                {point}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

// Utility Components
const SectionHeading = ({ title }) => (
  <h2 className="text-xl font-bold border-b-2 w-full border-black mt-4">
    {title}
  </h2>
);

const InputField = ({ label, placeholder, value, onChange }) => (
  <div>
    {console.log(label, "from 467")}
    <label className="block text-gray-900 font-semibold mb-1">
      {placeholder}
    </label>
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="input border-2 px-3 py-2 w-full border-gray-400 rounded-lg focus:border-blue-500"
    />
  </div>
);

const Button = ({ onClick, label }) => (
  <button
    onClick={onClick}
    className={`${
      label === "Delete Section"
        ? "bg-red-500 hover:bg-red-600 text-white px-3 py-2  shadow-md"
        : label === "Add Point"
        ? "bg-green-500 hover:bg-green-600 text-white px-2 py-1  shadow-lg rounded-full"
        : "bg-blue-500 hover:bg-blue-600 text-white px-5 py-2 w-40  shadow-md rounded-md"
    } transition-all duration-300 ease-in-out opacity-85 rounded-lg mt-3 flex items-center justify-center`}
  >
    {label}
  </button>
);

const ConditionalLink = ({ label, url }) => (
  <a href={url} className="text-blue-500 underline mx-1">
    {label}
  </a>
);

const ExperienceProjectForm = ({
  section,
  data,
  idx,
  onChange,
  handlePointsChange,
  addPoint,
  deleteSectionItem,
  deletePoint,
}) => (
  <div className="mb-4">
    {section && section == "education" ? (
      <div className=" space-y-2 grid-cols-2">
        <InputField
          placeholder="Institution"
          value={data.institution}
          onChange={(e) => onChange(e, "education", idx, "institution")}
        />
        <InputField
          placeholder="Degree"
          value={data.degree}
          onChange={(e) => onChange(e, "education", idx, "degree")}
        />
        <InputField
          placeholder="Start Year"
          value={data.startYear}
          onChange={(e) => onChange(e, "education", idx, "startYear")}
        />
        <InputField
          placeholder="End Year"
          value={data.endYear}
          onChange={(e) => onChange(e, "education", idx, "endYear")}
        />
        <InputField
          placeholder="Marks/CGPA"
          value={data.marks}
          onChange={(e) => onChange(e, "education", idx, "marks")}
        />
      </div>
    ) : section === "extra" ? (
      <>
        {/* Input for custom heading */}
        <InputField
          placeholder="Custom Heading"
          value={data?.heading || ""} // Make sure heading is initialized
          onChange={(e) => onChange(e, section, idx, "heading")}
        />

        {/* Map through points array */}
        {data?.points?.map((point, pointIdx) => (
          <div key={pointIdx} className="flex items-center mt-2">
            <InputField
              placeholder="Bullet point"
              value={point}
              onChange={(e) => handlePointsChange(e, section, idx, pointIdx)}
            />
            <button
              onClick={() => deletePoint(section, idx, pointIdx)}
              className="text-red-500 ml-2 mt-6"
            >
              Delete
            </button>
          </div>
        ))}

        <Button onClick={() => addPoint(section, idx)} label="Add Point" />
      </>
    ) : (
      <div className="space-y-2 grid-cols-2">
        <InputField
          placeholder={section === "experience" ? "Role" : "Project Name"}
          value={section === "experience" ? data.role : data.name}
          onChange={(e) =>
            onChange(
              e,
              section,
              idx,
              section === "experience" ? "role" : "name"
            )
          }
        />

        <InputField
          placeholder={section === "experience" ? "Company" : "Project Link"}
          value={section === "experience" ? data.company : data.projectLink}
          onChange={(e) =>
            onChange(
              e,
              section,
              idx,
              section === "experience" ? "company" : "projectLink"
            )
          }
        />
        <InputField
          placeholder="Technologies (comma separated)"
          value={data?.technologies}
          onChange={(e) =>
            onChange(
              {
                target: {
                  value: e.target.value,
                },
              },
              section,
              idx,
              "technologies"
            )
          }
        />

        <InputField
          placeholder="Date"
          value={data.date}
          onChange={(e) => onChange(e, section, idx, "date")}
        />

        {data.points.map((point, pointIdx) => (
          <div key={pointIdx} className="flex  items-center">
            <InputField
              placeholder="Bullet point"
              value={point}
              onChange={(e) => handlePointsChange(e, section, idx, pointIdx)}
            />
            <button
              onClick={() => deletePoint(section, idx, pointIdx)}
              className="text-red-500 ml-2 mt-6"
            >
              Delete
            </button>
          </div>
        ))}

        <Button onClick={() => addPoint(section, idx)} label="Add Point" />
      </div>
    )}
    <Button
      onClick={() => deleteSectionItem(section, idx)}
      label="Delete Section"
      className="bg-red-500"
    />
  </div>
);

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export default ResumeBuilder;
