import React from "react";
import OtpForm from "../components/auth/OtpForm";

const OtpPage = () => {
  return (
    <div>
      <OtpForm />
    </div>
  );
};

export default OtpPage;
