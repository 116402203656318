import React from "react";

import MatchWithjdpage from "../components/matchresume/MatchWithjdpage";
const MatchWithJd = () => {
  return (
    <div>
      <MatchWithjdpage />
    </div>
  );
};

export default MatchWithJd;
