import React from "react";
import ResumeBuilder from "../components/resumeBuilder/ResumeBuilder";

const MakeResume = () => {
  return (
    <div>
      <ResumeBuilder />
    </div>
  );
};

export default MakeResume;
