import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
// import img1 from "../../images/Designer7.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import Cookies from "js-cookie";
const LoginButton = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    setloading(true);
    try {
      const data = await AuthService.login(email, password);
      Cookies.set("token", data.token);
      Cookies.set("email", data.email);
      Cookies.set("userName", data.name);
      Cookies.set("formSubmitted", data.formSubmitted);
      console.log("Login successful", data);
      navigate("/");
      setloading(false);
    } catch (error) {
      console.error("Login failed", error);

      toast.warning(`${error.message}`, {
        position: "top-right",
      });
      setError(error.message || "Login failed");
      setloading(false);
    }
  };

  const Googlelogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setloading(true);
      try {
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );

        if (!res.ok) {
          throw new Error("Failed to fetch user info");
        }

        const userInfo = await res.json();
        console.log("User Info:", userInfo);

        const data = await AuthService.login(userInfo.email, "", userInfo.sub);
        Cookies.set("token", data.token);
        Cookies.set("email", data.email);
        Cookies.set("userName", userInfo.name);
        console.log("Login successful", data);
        navigate("/");
        setloading(false);
      } catch (error) {
        console.error("Error fetching user info:", error);
        toast.warning(`${error.message}`, {
          position: "top-right",
        });
        setError(error.message || "Login failed");
        setloading(false);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setError(error.message || "Login failed");
    },
  });

  const handleForgotPassword = () => {
    navigate("/enter-email");
  };

  return (
    <div className="flex flex-col lg:flex-row items-center">
      {loading && <Loading work="Logging In" />}
      <div className="bg-white w-full lg:w-1/2 min-h-screen py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-lg mt-10 px-4 md:px-8">
          <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">
            Login
          </h2>
          <form
            className="mx-auto max-w-lg rounded-lg border"
            onSubmit={handleEmailLogin}
          >
            <div className="flex flex-col gap-4 p-4 md:p-8">
              <div>
                <label
                  htmlFor="email"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="mb-2 inline-block text-sm text-gray-800 sm:text-base"
                >
                  Password
                </label>
                <input
                  name="password"
                  type="password"
                  className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <p className="text-sm text-center text-gray-500">
                <button
                  type="button"
                  className="text-blue-600 hover:underline"
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </button>
              </p>
              <button
                type="submit"
                className="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base"
              >
                Log in
              </button>
              <div className="relative flex items-center justify-center">
                <span className="absolute inset-x-0 h-px bg-gray-300" />
                <span className="relative bg-white px-4 text-sm text-gray-400">
                  Log in with social
                </span>
              </div>

              <div className="relative flex w-full group">
                <button
                  type="button"
                  disabled={true}
                  className="flex w-full cursor-not-allowed items-center justify-center gap-2 rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 group-hover:blur"
                >
                  <svg
                    className="h-5 w-5 shrink-0"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
                      fill="white"
                    />
                  </svg>
                  Continue with GitHub
                </button>
                <div className="absolute inset-0 w-full h-full bg-opacity-60 backdrop-blur-md flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
                  <div className="flex z-10 cursor-pointer justify-center p-3 bg-green-200 rounded-xl flex-row space-x-3 items-center">
                    <span className="text-lg font-semibold text-gray-800 flex items-center">
                      Coming Soon
                    </span>
                    <svg
                      className="text-2xl justify-center items-center"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0C5.373 0 0 5.373 0 12c0 5.303 3.438 9.8 8.205 11.387.6.113.82-.26.82-.577 0-.285-.01-1.04-.016-2.04-3.338.724-4.042-1.613-4.042-1.613-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.73.082-.73 1.204.084 1.837 1.236 1.837 1.236 1.07 1.833 2.809 1.303 3.495.996.108-.774.42-1.303.763-1.603-2.665-.304-5.466-1.332-5.466-5.933 0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.984-.4 3.006-.404 1.022.004 2.046.137 3.006.404 2.29-1.553 3.298-1.23 3.298-1.23.654 1.653.242 2.874.118 3.176.768.84 1.236 1.91 1.236 3.22 0 4.61-2.803 5.625-5.475 5.922.43.37.814 1.103.814 2.222 0 1.604-.014 2.896-.014 3.292 0 .32.218.694.824.576C20.565 21.797 24 17.303 24 12c0-6.627-5.373-12-12-12z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={() => Googlelogin()}
                className="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-[#FBF8F7]  px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base"
              >
                <svg
                  className="h-5 w-5 shrink-0"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.2222 12.2222C22.2222 11.5555 22.1666 10.8889 22.0555 10.2777H12V14.1666H17.5C17.2333 15.3888 16.5 16.3888 15.4444 17.0555L18.6666 19.3333C20.5 17.6666 22.2222 15.1111 22.2222 12.2222Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 22C14.7778 22 17.1111 21.0555 18.6667 19.3333L15.4445 17.0555C14.5555 17.6111 13.3333 17.8889 12 17.8889C9.33333 17.8889 7.11111 16.1111 6.33333 13.6667L2.99999 16.0555C4.55555 19.4444 8 22 12 22Z"
                    fill="#34A853"
                  />
                  <path
                    d="M6.33333 13.6666C6.11111 13.1111 6 12.5555 6 12C6 11.4444 6.11111 10.8889 6.33333 10.3333L3 7.94444C2.22222 9.44444 2 10.6667 2 12C2 13.3333 2.22222 14.5555 3 16.0555L6.33333 13.6666Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 6.11111C13.3333 6.11111 14.5555 6.55555 15.4444 7.44444L18.7778 4.11111C17.1111 2.55555 14.7778 1.77778 12 1.77778C8 1.77778 4.55555 4.33333 3 7.94444L6.33333 10.3333C7.11111 7.88889 9.33333 6.11111 12 6.11111Z"
                    fill="#EA4335"
                  />
                </svg>
                Continue with Google
              </button>
            </div>
            <div className="flex items-center justify-center bg-gray-100 p-4">
              <p className="text-center text-sm text-gray-500">
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  className="text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700"
                >
                  Register
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="relative hidden h-screen w-full lg:flex lg:w-1/2 flex-col justify-center items-center bg-[url('https://tse4.mm.bing.net/th?id=OIG4.P643wgR0bZ430Y8yJlrJ&pid=ImgGn')] bg-cover bg-center">
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="z-10 text-center">
          <h2 className="text-4xl font-bold text-white sm:text-5xl">
            MatchMyResume
          </h2>
          <p className="mt-4 text-lg text-white sm:text-2xl">
            Find your dream job with ease.
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginButton;
