import React from "react";
import LoginButton from "../components/auth/Login";

const Login = () => {
  return (
    <div>
      <LoginButton />
    </div>
  );
};

export default Login;
