import React from "react";
import ShowHistory from "../components/ResumeHistory/ShowHistory";
const ResumeHistory = () => {
  return (
    <div>
      <ShowHistory />
    </div>
  );
};

export default ResumeHistory;
