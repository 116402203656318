import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaGithub, FaLinkedin, FaSquareInstagram } from "react-icons/fa6";
import Cookies from "js-cookie";
import { ThemeContext } from "../../ThemeContext";
const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const storedEmail = Cookies.get("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim() || !message.trim()) {
      toast.error("Please fill out all fields.");
      return;
    }

    try {
      await axios.post(
        "https://matchmyresumebackend.vercel.app/api/contact-us",
        {
          email,
          name,
          message,
        }
      );
      toast.success("Your message has been submitted successfully.");
      setName("");
      setMessage("");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting your message.");
    }
  };

  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <section
        className={`body-font relative ${
          theme === "dark" ? "text-gray-300" : "text-gray-600"
        }`}
      >
        <div className="container px-5 my-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1
              className={`sm:text-3xl text-2xl font-medium title-font mb-4 ${
                theme === "dark" ? "text-white" : "text-gray-900"
              }`}
            >
              Contact Us
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Having any issue ? Please feel free to contact.
            </p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className={`leading-7 text-sm ${
                      theme === "dark" ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`w-full bg-gray-100 bg-opacity-50 rounded border ${
                      theme === "dark"
                        ? "border-gray-500 focus:border-indigo-400 text-gray-200"
                        : "border-gray-300 focus:border-indigo-500 text-gray-700"
                    } focus:bg-white focus:ring-2 ${
                      theme === "dark"
                        ? "focus:ring-indigo-400"
                        : "focus:ring-indigo-200"
                    } py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className={`leading-7 text-sm ${
                      theme === "dark" ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    disabled
                    className={`w-full cursor-not-allowed bg-gray-100 bg-opacity-50 rounded border ${
                      theme === "dark"
                        ? "border-gray-500 focus:border-indigo-400 text-gray-200"
                        : "border-gray-300 focus:border-indigo-500 text-gray-700"
                    } focus:bg-white focus:ring-2 ${
                      theme === "dark"
                        ? "focus:ring-indigo-400"
                        : "focus:ring-indigo-200"
                    } py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className={`leading-7 text-sm ${
                      theme === "dark" ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className={`w-full bg-gray-100 bg-opacity-50 rounded border ${
                      theme === "dark"
                        ? "border-gray-500 focus:border-indigo-400 text-gray-200"
                        : "border-gray-300 focus:border-indigo-500 text-gray-700"
                    } focus:bg-white focus:ring-2 ${
                      theme === "dark"
                        ? "focus:ring-indigo-400"
                        : "focus:ring-indigo-200"
                    } h-32 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out`}
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <button
                  onClick={handleSubmit}
                  className={`flex mx-auto text-white ${
                    theme === "dark"
                      ? "bg-indigo-600 hover:bg-indigo-700"
                      : "bg-indigo-500 hover:bg-indigo-600"
                  } border-0 py-2 px-8 focus:outline-none rounded text-lg`}
                >
                  Send
                </button>
              </div>
              <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                <a className="text-indigo-500">abhiapplee@gmail.com</a>
                <p className="leading-normal my-5">
                  Asavari Building, flat no: 1801, wing-A, <br />
                  varthak nagar, sastri nagar chowk, Thane West, Mumbai
                </p>

                <span className="inline-flex justify-center space-x-9 w-full">
                  <a
                    href="https://www.linkedin.com/in/abhi-apple/"
                    target="_blank"
                    className="flex"
                  >
                    <FaLinkedin className="hover:animate-bounce text-3xl" />
                  </a>
                  <a
                    href="https://github.com/abhi-apple"
                    target="_blank"
                    className="flex"
                  >
                    <FaGithub className="hover:animate-bounce text-3xl" />
                  </a>
                  <a
                    href="https://www.instagram.com/abhinay_reddy_gujjala/"
                    target="_blank"
                    className="flex"
                  >
                    <FaSquareInstagram className="hover:animate-bounce text-3xl" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
