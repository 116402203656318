import React from "react";
import ResumeRoleAi from "../components/ResumeEnhance/ResumeRoleAi";

const ResumeEnhancePage = () => {
  return (
    <div>
      <ResumeRoleAi />
    </div>
  );
};

export default ResumeEnhancePage;
