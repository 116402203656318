import React, { useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../ThemeContext";

const JobSideBar = ({
  jobs,
  fetchMoreJobs,
  hasMore,
  loading,
  setSelectedJob,
  onJobClick,
}) => {
  const jobSidebarRef = useRef(null);

  const handleScroll = () => {
    const sidebar = jobSidebarRef.current;
    if (
      sidebar.scrollTop + sidebar.clientHeight >= sidebar.scrollHeight - 10 &&
      hasMore &&
      !loading
    ) {
      fetchMoreJobs(); // Fetch more jobs when the user scrolls to the bottom
    }
  };

  useEffect(() => {
    const sidebar = jobSidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, loading]);
  const { theme } = useContext(ThemeContext);

  return (
    <div ref={jobSidebarRef} className={`overflow-y-scroll h-[80vh] `}>
      <section
        className={`flex flex-col pt-3 pr-2 border-r-2 h-full ${
          theme === "light" ? "border-gray-200" : "border-gray-700"
        }`}
      >
        <ul className="mt-6">
          {jobs.map((job) => (
            <li
              key={job.id}
              className={`py-5 border-b px-3 transition cursor-pointer ${
                theme === "light"
                  ? "hover:bg-indigo-100 border-gray-200"
                  : "hover:bg-gray-800 border-gray-700"
              }`}
              onClick={() => onJobClick(job)}
            >
              <div className="flex justify-between items-center">
                <h3
                  className={`text-lg font-semibold ${
                    theme === "light" ? "text-gray-700" : "text-gray-100"
                  }`}
                >
                  {job.title}
                </h3>
              </div>
              <div
                className={`text-md italic ${
                  theme === "light" ? "text-gray-500" : "text-gray-400"
                }`}
              >
                {job.company.display_name}
              </div>
            </li>
          ))}
        </ul>
      </section>
      {loading && (
        <p className={theme === "light" ? "text-gray-700" : "text-gray-200"}>
          Loading more jobs...
        </p>
      )}
      {!hasMore && (
        <p className={theme === "light" ? "text-gray-700" : "text-gray-200"}>
          No more jobs available.
        </p>
      )}
    </div>
  );
};

export default JobSideBar;
