import { lighten, darken } from "polished";
export const ResumeSkillsDemo = [
  "Software",
  "Software development",
  "Source code",
  "Troubleshooting",
  "Technology",
  "Computer",
  "Computer science",
  "Technology",
  "HTML",
  "CSS",
  "JavaScript",
  "Software framework",
  "React (software)",
  "Angular (web framework)",
  "Node.js",
  "Database",
  "SQL",
  "Problem solving",
  "Scalability",
  "World Wide Web",
  "Web application",
  "Application software",
];

export const JdSkillsDemo = [
  "Software development process",
  "Product lifecycle",
  "Concept",
  "Debugging",
  "User (computing)",
  "User experience design",
  "Experience",
  "Emerging technologies",
  "Frontend and backend",
  "Understanding",
  "Attention",
  "Communication",
  "Deployment environment",
  "Cross-functional team",
];

export const teamMembers = [
  {
    imgSrc: "https://dummyimage.com/80x80",
    name: "Holden Caulfield",
    role: "UI Designer",
  },
  {
    imgSrc: "https://dummyimage.com/84x84",
    name: "Henry Letham",
    role: "CTO",
  },
  {
    imgSrc: "https://dummyimage.com/88x88",
    name: "Oskar Blinde",
    role: "Founder",
  },
  {
    imgSrc: "https://dummyimage.com/90x90",
    name: "John Doe",
    role: "DevOps",
  },
  {
    imgSrc: "https://dummyimage.com/94x94",
    name: "Martin Eden",
    role: "Software Engineer",
  },
  {
    imgSrc: "https://dummyimage.com/98x98",
    name: "Boris Kitua",
    role: "UX Researcher",
  },
  {
    imgSrc: "https://dummyimage.com/100x90",
    name: "Atticus Finch",
    role: "QA Engineer",
  },
  {
    imgSrc: "https://dummyimage.com/104x94",
    name: "Alper Kamu",
    role: "System",
  },
  {
    imgSrc: "https://dummyimage.com/108x98",
    name: "Rodrigo Monchi",
    role: "Product Manager",
  },
];
export const getMatchReview = (percentage) => {
  if (percentage <= 20) {
    return {
      heading: "Oops! The match score is too low 😥",
      description:
        "We recommend a match score of 70% before applying. Improve the chances of your application getting noticed by adding the following keywords that we have identified to your resume.",
    };
  } else if (percentage <= 40) {
    return {
      heading: "The match score is below average 😟",
      description:
        "Consider adding more relevant skills and experiences to your resume to improve your match score.",
    };
  } else if (percentage <= 60) {
    return {
      heading: "The match score is average 😐",
      description:
        "Your resume has a decent match score. Adding a few more relevant details can increase your chances of getting noticed.",
    };
  } else if (percentage <= 80) {
    return {
      heading: "Good match score! 😊",
      description:
        "Your resume matches well with the job description. A few minor improvements can make it even better.",
    };
  } else {
    return {
      heading: "Excellent match score! 🎉",
      description:
        "Your resume is a great match for the job description. You have a high chance of getting noticed!",
    };
  }
};

const colorRanges = [
  { min: 0, max: 10, color: "#ff0000" }, // Red
  { min: 11, max: 20, color: "#ff3300" },
  { min: 21, max: 30, color: "#ff6600" },
  { min: 31, max: 40, color: "#ff9900" },
  { min: 41, max: 50, color: "#ffcc00" },
  { min: 51, max: 60, color: "#ffff00" }, // Yellow
  { min: 61, max: 70, color: "#ccff00" },
  { min: 71, max: 80, color: "#99ff00" },
  { min: 81, max: 90, color: "#66ff00" },
  { min: 91, max: 100, color: "#00ff00" }, // Green
];

export const getColorForPercentage = (percentage) => {
  for (const range of colorRanges) {
    if (percentage >= range.min && percentage <= range.max) {
      return range.color;
    }
  }
  return "#ff0000"; // Default to red if no range matches
};

export const getBackgroundColor = (color) => {
  return lighten(0.4, color); // Lighten the color
};

export const getTextColor = (color) => {
  return darken(0.2, color); // Darken the color
};

export const preJDData = [
  {
    role: "Full Stack Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Software Engineering, or related field.",
        "Basic understanding of front-end and back-end development concepts.",
        "Proficiency in programming languages such as JavaScript, HTML, CSS.",
        "Eagerness to learn and grow in a fast-paced development environment.",
        "Strong problem-solving and communication skills.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "Full Stack Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science or related field.",
        "1-2 years of experience in full stack development.",
        "Proficiency in front-end technologies (JavaScript, HTML, CSS) and back-end technologies (Node.js, Python, Ruby).",
        "Experience with databases (SQL and NoSQL).",
        "Strong problem-solving skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Full Stack Mid",
    description: {
      requirements: [
        "3-5 years of experience in full stack development.",
        "Strong expertise in both front-end and back-end technologies.",
        "Experience with API design and development.",
        "Knowledge of performance optimization techniques.",
        "Experience with agile development methodologies.",
        "Excellent teamwork and communication skills.",
      ],
    },
  },
  {
    role: "Full Stack Senior",
    description: {
      requirements: [
        "5-8 years of experience in full stack development.",
        "Deep expertise in both front-end and back-end technologies.",
        "Proven track record of delivering complex web applications.",
        "Experience with system architecture and design patterns.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "Full Stack Lead",
    description: {
      requirements: [
        "8+ years of experience in full stack development.",
        "Proven experience leading development teams and projects.",
        "Expertise in scaling web applications and systems.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and decision-making abilities.",
      ],
    },
  },
  {
    role: "Full Stack Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing full stack development teams.",
        "Strong technical background with expertise in web technologies.",
        "Experience with project management methodologies.",
        "Excellent leadership, communication, and organizational skills.",
        "Ability to drive innovation and improve development processes.",
      ],
    },
  },
  {
    role: "Backend Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Software Engineering, or related field.",
        "Basic understanding of server-side languages and frameworks.",
        "Knowledge of databases and data modeling.",
        "Eagerness to learn and adapt in a fast-paced development environment.",
        "Strong problem-solving and analytical skills.",
        "Ability to work well in a team.",
      ],
    },
  },
  {
    role: "Backend Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science or related field.",
        "1-2 years of experience in back-end development.",
        "Proficiency in server-side languages and frameworks (e.g., Node.js, Python, Java).",
        "Experience with databases (SQL and NoSQL).",
        "Strong understanding of API design and development.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Backend Mid",
    description: {
      requirements: [
        "3-5 years of experience in back-end development.",
        "Expertise in server-side languages and frameworks.",
        "Experience with system design and architecture.",
        "Knowledge of performance optimization techniques.",
        "Experience with agile development methodologies.",
        "Strong problem-solving and communication skills.",
      ],
    },
  },
  {
    role: "Backend Senior",
    description: {
      requirements: [
        "5-8 years of experience in back-end development.",
        "Deep expertise in server-side technologies and system design.",
        "Proven track record of delivering scalable back-end solutions.",
        "Experience with database optimization and tuning.",
        "Strong leadership and mentoring skills.",
        "Ability to manage complex projects and systems.",
      ],
    },
  },
  {
    role: "Backend Lead",
    description: {
      requirements: [
        "8+ years of experience in back-end development.",
        "Proven experience leading development teams and projects.",
        "Expertise in building and scaling server-side applications.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Backend Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing back-end development teams.",
        "Strong technical background with expertise in server-side technologies.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Front End Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Web Development, or related field.",
        "Basic knowledge of HTML, CSS, and JavaScript.",
        "Understanding of front-end frameworks like React, Angular, or Vue.js.",
        "Eagerness to learn and adapt in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a team.",
      ],
    },
  },
  {
    role: "Front End Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Web Development, or related field.",
        "1-2 years of experience in front-end development.",
        "Proficiency in HTML, CSS, and JavaScript.",
        "Experience with front-end frameworks like React, Angular, or Vue.js.",
        "Strong understanding of responsive design and accessibility.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Front End Mid",
    description: {
      requirements: [
        "3-5 years of experience in front-end development.",
        "Strong expertise in HTML, CSS, and JavaScript.",
        "Experience with modern front-end frameworks and libraries.",
        "Knowledge of performance optimization and best practices.",
        "Experience with agile development methodologies.",
        "Excellent problem-solving and communication skills.",
      ],
    },
  },
  {
    role: "Front End Senior",
    description: {
      requirements: [
        "5-8 years of experience in front-end development.",
        "Deep expertise in front-end technologies and frameworks.",
        "Proven track record of delivering high-quality user interfaces.",
        "Experience with UX/UI design principles.",
        "Strong leadership and mentoring skills.",
        "Ability to manage complex projects and coordinate with other teams.",
      ],
    },
  },
  {
    role: "Front End Lead",
    description: {
      requirements: [
        "8+ years of experience in front-end development.",
        "Proven experience leading front-end development teams.",
        "Expertise in building and scaling user interfaces.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Front End Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing front-end development teams.",
        "Strong technical background with expertise in front-end technologies.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Blockchain Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Engineering, or related field.",
        "Basic understanding of blockchain technologies and smart contracts.",
        "Proficiency in programming languages such as Solidity and JavaScript.",
        "Eagerness to learn and adapt to new technologies.",
        "Strong problem-solving skills and attention to detail.",
        "Ability to work effectively in a team environment.",
      ],
    },
  },
  {
    role: "Blockchain Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Engineering, or related field.",
        "1-2 years of experience in blockchain development.",
        "Experience with blockchain platforms and smart contract development.",
        "Proficiency in languages such as Solidity and JavaScript.",
        "Strong understanding of blockchain security principles.",
        "Good problem-solving and analytical skills.",
      ],
    },
  },
  {
    role: "Blockchain Mid",
    description: {
      requirements: [
        "3-5 years of experience in blockchain development.",
        "Expertise in blockchain technologies and smart contract design.",
        "Experience with building and maintaining decentralized applications.",
        "Knowledge of cryptographic principles and blockchain security.",
        "Strong problem-solving and communication skills.",
        "Experience with agile development methodologies.",
      ],
    },
  },
  {
    role: "Blockchain Senior",
    description: {
      requirements: [
        "5-8 years of experience in blockchain development.",
        "Deep expertise in blockchain technologies and decentralized systems.",
        "Proven track record of delivering complex blockchain solutions.",
        "Experience with blockchain architecture and system design.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities effectively.",
      ],
    },
  },
  {
    role: "Blockchain Lead",
    description: {
      requirements: [
        "8+ years of experience in blockchain development.",
        "Proven experience leading blockchain development teams.",
        "Expertise in building and scaling blockchain solutions.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Blockchain Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing blockchain development teams.",
        "Strong technical background with expertise in blockchain technologies.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Gaming Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Game Development, or related field.",
        "Basic understanding of game development concepts and tools.",
        "Familiarity with game engines like Unity or Unreal Engine.",
        "Eagerness to learn and grow in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "Gaming Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Game Development, or related field.",
        "1-2 years of experience in game development.",
        "Proficiency with game engines like Unity or Unreal Engine.",
        "Experience with game design principles and techniques.",
        "Strong problem-solving skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Gaming Mid",
    description: {
      requirements: [
        "3-5 years of experience in game development.",
        "Strong expertise in game engines and development tools.",
        "Experience with game design and development pipelines.",
        "Knowledge of performance optimization techniques.",
        "Experience with agile development methodologies.",
        "Excellent teamwork and communication skills.",
      ],
    },
  },
  {
    role: "Gaming Senior",
    description: {
      requirements: [
        "5-8 years of experience in game development.",
        "Deep expertise in game engines and advanced development techniques.",
        "Proven track record of delivering high-quality games.",
        "Experience with game architecture and design patterns.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "Gaming Lead",
    description: {
      requirements: [
        "8+ years of experience in game development.",
        "Proven experience leading game development teams.",
        "Expertise in building and scaling complex gaming systems.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Gaming Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science, Game Development, or related field.",
        "Proven experience managing game development teams.",
        "Strong technical background with expertise in game development.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "iOS Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Software Engineering, or related field.",
        "Basic understanding of iOS development concepts and tools.",
        "Familiarity with Swift and Objective-C.",
        "Eagerness to learn and grow in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "iOS Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Software Engineering, or related field.",
        "1-2 years of experience in iOS development.",
        "Proficiency in Swift and Objective-C.",
        "Experience with iOS frameworks and APIs.",
        "Strong problem-solving skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "iOS Mid",
    description: {
      requirements: [
        "3-5 years of experience in iOS development.",
        "Strong expertise in Swift and Objective-C.",
        "Experience with iOS app architecture and design patterns.",
        "Knowledge of performance optimization techniques.",
        "Experience with agile development methodologies.",
        "Excellent teamwork and communication skills.",
      ],
    },
  },
  {
    role: "iOS Senior",
    description: {
      requirements: [
        "5-8 years of experience in iOS development.",
        "Deep expertise in iOS technologies and frameworks.",
        "Proven track record of delivering high-quality iOS applications.",
        "Experience with app architecture and design principles.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "iOS Lead",
    description: {
      requirements: [
        "8+ years of experience in iOS development.",
        "Proven experience leading iOS development teams.",
        "Expertise in building and scaling iOS applications.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "iOS Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing iOS development teams.",
        "Strong technical background with expertise in iOS development.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Android Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Software Engineering, or related field.",
        "Basic understanding of Android development concepts and tools.",
        "Familiarity with Java and Kotlin.",
        "Eagerness to learn and grow in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "Android Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Software Engineering, or related field.",
        "1-2 years of experience in Android development.",
        "Proficiency in Java and Kotlin.",
        "Experience with Android frameworks and APIs.",
        "Strong problem-solving skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Android Mid",
    description: {
      requirements: [
        "3-5 years of experience in Android development.",
        "Strong expertise in Java and Kotlin.",
        "Experience with Android app architecture and design patterns.",
        "Knowledge of performance optimization techniques.",
        "Experience with agile development methodologies.",
        "Excellent teamwork and communication skills.",
      ],
    },
  },
  {
    role: "Android Senior",
    description: {
      requirements: [
        "5-8 years of experience in Android development.",
        "Deep expertise in Android technologies and frameworks.",
        "Proven track record of delivering high-quality Android applications.",
        "Experience with app architecture and design principles.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "Android Lead",
    description: {
      requirements: [
        "8+ years of experience in Android development.",
        "Proven experience leading Android development teams.",
        "Expertise in building and scaling Android applications.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Android Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing Android development teams.",
        "Strong technical background with expertise in Android development.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Data Analyst Intern",
    description: {
      requirements: [
        "Pursuing a degree in Data Science, Statistics, Computer Science, or related field.",
        "Basic knowledge of data analysis tools and techniques.",
        "Familiarity with Excel, SQL, and data visualization tools.",
        "Eagerness to learn and adapt in a fast-paced environment.",
        "Strong analytical and problem-solving skills.",
        "Ability to work effectively in a team.",
      ],
    },
  },
  {
    role: "Data Analyst Junior",
    description: {
      requirements: [
        "Bachelor's degree in Data Science, Statistics, Computer Science, or related field.",
        "1-2 years of experience in data analysis.",
        "Proficiency with SQL, Excel, and data visualization tools.",
        "Experience with statistical analysis and data modeling.",
        "Strong analytical skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Data Analyst Mid",
    description: {
      requirements: [
        "3-5 years of experience in data analysis.",
        "Strong expertise in data analysis tools and techniques.",
        "Experience with advanced statistical analysis and data modeling.",
        "Knowledge of data warehousing and ETL processes.",
        "Strong problem-solving and communication skills.",
        "Experience with agile development methodologies.",
      ],
    },
  },
  {
    role: "Data Analyst Senior",
    description: {
      requirements: [
        "5-8 years of experience in data analysis.",
        "Deep expertise in data analysis and data-driven decision-making.",
        "Proven track record of delivering actionable insights.",
        "Experience with data visualization and reporting tools.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "Data Analyst Lead",
    description: {
      requirements: [
        "8+ years of experience in data analysis.",
        "Proven experience leading data analysis teams.",
        "Expertise in advanced data analytics and data strategy.",
        "Strong technical leadership and project management skills.",
        "Experience with data governance and quality management.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Data Analyst Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Data Science, Statistics, or related field.",
        "Proven experience managing data analysis teams.",
        "Strong technical background with expertise in data analytics.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "DevOps Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Engineering, or related field.",
        "Basic understanding of DevOps concepts and tools.",
        "Familiarity with version control systems like Git.",
        "Eagerness to learn and grow in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "DevOps Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Engineering, or related field.",
        "1-2 years of experience in DevOps or related roles.",
        "Proficiency with CI/CD tools and pipelines.",
        "Experience with cloud platforms like AWS, Azure, or Google Cloud.",
        "Strong understanding of infrastructure as code (IaC) principles.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "DevOps Mid",
    description: {
      requirements: [
        "3-5 years of experience in DevOps.",
        "Strong expertise in CI/CD practices and tools.",
        "Experience with cloud infrastructure and deployment.",
        "Knowledge of containerization technologies like Docker and Kubernetes.",
        "Experience with monitoring and logging tools.",
        "Strong problem-solving and communication skills.",
      ],
    },
  },
  {
    role: "DevOps Senior",
    description: {
      requirements: [
        "5-8 years of experience in DevOps.",
        "Deep expertise in DevOps practices and cloud technologies.",
        "Proven track record of implementing and managing complex CI/CD pipelines.",
        "Experience with system architecture and performance optimization.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "DevOps Lead",
    description: {
      requirements: [
        "8+ years of experience in DevOps.",
        "Proven experience leading DevOps teams and projects.",
        "Expertise in building and scaling CI/CD systems and cloud infrastructure.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps best practices and process improvements.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "DevOps Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science, Engineering, or related field.",
        "Proven experience managing DevOps teams.",
        "Strong technical background with expertise in DevOps practices and cloud technologies.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "Site Reliability Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Engineering, or related field.",
        "Basic understanding of site reliability concepts and tools.",
        "Familiarity with monitoring and alerting systems.",
        "Eagerness to learn and grow in a fast-paced environment.",
        "Good problem-solving skills and attention to detail.",
        "Ability to work well in a collaborative team setting.",
      ],
    },
  },
  {
    role: "Site Reliability Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Engineering, or related field.",
        "1-2 years of experience in site reliability or related roles.",
        "Proficiency with monitoring and alerting tools.",
        "Experience with incident management and response.",
        "Strong understanding of infrastructure and system performance.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "Site Reliability Mid",
    description: {
      requirements: [
        "3-5 years of experience in site reliability engineering.",
        "Strong expertise in monitoring, alerting, and incident response.",
        "Experience with cloud infrastructure and performance optimization.",
        "Knowledge of automation and scripting.",
        "Experience with agile development methodologies.",
        "Excellent problem-solving and communication skills.",
      ],
    },
  },
  {
    role: "Site Reliability Senior",
    description: {
      requirements: [
        "5-8 years of experience in site reliability engineering.",
        "Deep expertise in monitoring and incident management.",
        "Proven track record of improving system reliability and performance.",
        "Experience with site reliability architecture and design.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "Site Reliability Lead",
    description: {
      requirements: [
        "8+ years of experience in site reliability engineering.",
        "Proven experience leading site reliability teams and projects.",
        "Expertise in building and scaling reliable systems.",
        "Strong technical leadership and project management skills.",
        "Experience with DevOps practices and CI/CD pipelines.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "Site Reliability Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science or related field.",
        "Proven experience managing site reliability teams.",
        "Strong technical background with expertise in site reliability engineering.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
  {
    role: "QA Testing Intern",
    description: {
      requirements: [
        "Pursuing a degree in Computer Science, Software Engineering, or related field.",
        "Basic understanding of QA testing concepts and tools.",
        "Familiarity with test case creation and execution.",
        "Eagerness to learn and adapt in a fast-paced environment.",
        "Good attention to detail and problem-solving skills.",
        "Ability to work effectively in a team.",
      ],
    },
  },
  {
    role: "QA Testing Junior",
    description: {
      requirements: [
        "Bachelor's degree in Computer Science, Software Engineering, or related field.",
        "1-2 years of experience in QA testing.",
        "Experience with test case creation and execution.",
        "Familiarity with automated testing tools and frameworks.",
        "Strong problem-solving skills and attention to detail.",
        "Experience with version control systems like Git.",
      ],
    },
  },
  {
    role: "QA Testing Mid",
    description: {
      requirements: [
        "3-5 years of experience in QA testing.",
        "Strong expertise in manual and automated testing techniques.",
        "Experience with test planning, execution, and reporting.",
        "Knowledge of testing tools and frameworks.",
        "Strong analytical and problem-solving skills.",
        "Experience with agile development methodologies.",
      ],
    },
  },
  {
    role: "QA Testing Senior",
    description: {
      requirements: [
        "5-8 years of experience in QA testing.",
        "Deep expertise in QA methodologies and best practices.",
        "Proven track record of delivering high-quality software.",
        "Experience with test automation and performance testing.",
        "Strong leadership and mentoring skills.",
        "Ability to manage multiple projects and priorities.",
      ],
    },
  },
  {
    role: "QA Testing Lead",
    description: {
      requirements: [
        "8+ years of experience in QA testing.",
        "Proven experience leading QA teams and projects.",
        "Expertise in building and scaling QA processes.",
        "Strong technical leadership and project management skills.",
        "Experience with test strategy and process improvements.",
        "Excellent problem-solving and strategic planning abilities.",
      ],
    },
  },
  {
    role: "QA Testing Manager",
    description: {
      requirements: [
        "Bachelor's or Master's degree in Computer Science, Software Engineering, or related field.",
        "Proven experience managing QA testing teams.",
        "Strong technical background with expertise in QA testing.",
        "Experience with project management methodologies and team leadership.",
        "Excellent organizational, communication, and leadership skills.",
        "Ability to drive technical innovation and process improvements.",
      ],
    },
  },
];

export const articles = [
  {
    imageUrl:
      "https://images.unsplash.com/photo-1593508512255-86ab42a8e620?auto=format&q=75&fit=crop&w=600",
    date: "July 19, 2021",
    title: "New trends in Tech",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&q=75&fit=crop&w=600",
    date: "April 07, 2021",
    title: "Working with legacy stacks",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1542759564-7ccbb6ac450a?auto=format&q=75&fit=crop&w=600",
    date: "March 15, 2021",
    title: "10 best smartphones for devs",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1610465299996-30f240ac2b1c?auto=format&q=75&fit=crop&w=600",
    date: "January 27, 2021",
    title: "8 High performance Notebooks",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1610465299996-30f240ac2b1c?auto=format&q=75&fit=crop&w=600",
    date: "January 27, 2021",
    title: "8 High performance Notebooks",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1610465299996-30f240ac2b1c?auto=format&q=75&fit=crop&w=600",
    date: "January 27, 2021",
    title: "8 High performance Notebooks",
    description:
      "This is a section of some simple filler text, also known as placeholder text.",
    link: "#",
  },
];

export const jobOptions = [
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "Software Engineer", label: "Software Engineer" },
  { value: "QA Tester", label: "QA Tester" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "Site Reliability Engineer", label: "Site Reliability Engineer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Data Engineer", label: "Data Engineer" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Project Manager", label: "Project Manager" },
];

export const jobTypeOptions = [
  { value: "fulltime", label: "Full-time" },
  { value: "parttime", label: "Part-time" },
  { value: "contract", label: "Contract" },
  { value: "permanent", label: "Permanent" },
];

export const salaryOptions = [
  { value: "20000", label: "20,000+" },
  { value: "40000", label: "40,000+" },
  { value: "60000", label: "60,000+" },
  { value: "80000", label: "80,000+" },
  { value: "100000", label: "100,000+" },
];
export const locationOptions = [
  { value: "mumbai", label: "Mumbai" },
  { value: "delhi", label: "Delhi" },
  { value: "hyderabad", label: "Hyderabad" },
  { value: "pune", label: "Pune" },
  { value: "bangalore", label: "Bangalore" },
  { value: "chennai", label: "Chennai" },
  { value: "kolkata", label: "Kolkata" },
];
