import React from "react";
import { getMatchReview } from "../Demo/Skills";
const PercentReview = ({ percentage }) => {
  const { heading, description } = getMatchReview(percentage);
  return (
    <div className="flex flex-col space-y-2 ">
      <div className="flex font-extrabold">
        <h1>{heading}</h1>
      </div>
      <div className="flex text-slate-700">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PercentReview;
