import React, { useContext, useEffect, useRef, useState } from "react";
import { preJDData } from "../Demo/Skills";
import { ThemeContext } from "../../ThemeContext";

const MatchText = ({ setJdData, setOptionsData, page }) => {
  const textareaRef = useRef(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 2500;

  const handleTextareaChange = (event) => {
    let jdText = event.target.value;
    if (jdText.length <= maxChars) {
      setJdData(jdText);
      setCharCount(jdText.length);
    } else {
      jdText = jdText.slice(0, maxChars); // Trim to maxChars if necessary
      setJdData(jdText);
      textareaRef.current.value = jdText;
      setCharCount(maxChars);
    }
  };

  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const [selectedFirstOption, setSelectedFirstOption] =
    useState("Select an option");
  const [selectedSecondOption, setSelectedSecondOption] =
    useState("Select an option");

  const toggleFirstDropdown = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
    if (!isFirstDropdownOpen) {
      setIsSecondDropdownOpen(false);
    }
  };

  const toggleSecondDropdown = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
    if (!isSecondDropdownOpen) {
      setIsFirstDropdownOpen(false);
    }
  };

  const handleFirstOptionClick = (option) => {
    setSelectedFirstOption(option);
    setIsFirstDropdownOpen(false);
  };

  const handleSecondOptionClick = (option) => {
    setSelectedSecondOption(option);
    setIsSecondDropdownOpen(false);
  };

  const generateText = () => {
    const combinedOption = `${selectedFirstOption} ${selectedSecondOption}`;
    // setOptionsData(combinedOption);
    const roleData = preJDData.find((item) => item.role === combinedOption);

    if (roleData) {
      return roleData.description.requirements.join("\n");
    }
    return "No data available for the selected options.";
  };
  const handleGenerate = () => {
    const text = generateText();

    if (page === "matchJd") {
      textareaRef.current.value = text;
      setJdData(text);
      setCharCount(text.length);
    }
  };
  useEffect(() => {
    if (page != "matchJd") {
      const combinedOption = `${selectedFirstOption} ${selectedSecondOption}`;
      setOptionsData(combinedOption);
    }
  }, [selectedFirstOption, selectedSecondOption]);

  return (
    <div
      className={`flex flex-col items-center ${
        page !== "matchJd" ? "justify-evenly" : "justify-between"
      } ${theme === "dark" ? " text-white" : " text-black"}`}
    >
      {page === "matchJd" ? (
        <div className="flex">
          <h1 className="text-2xl font-semibold mb-2">Add Job Description</h1>
        </div>
      ) : (
        <div className="flex">
          <h1 className="text-2xl font-semibold mb-2">
            Select Your Job Role and Experience
          </h1>
        </div>
      )}

      <div className="max-w-2xl">
        {page === "matchJd" && (
          <p
            className={`mb-2 px-2 text-sm py-1 rounded-xl ${
              theme === "dark"
                ? " text-white bg-[#a2a94c]"
                : " text-black bg-[#e6eaa8]"
            } `}
          >
            Add the Job Description of the job you want to match your resume
            with.
          </p>
        )}

        <div className="mb-2 relative flex gap-4">
          <div>
            <button
              id="dropdownDefaultButton1"
              onClick={toggleFirstDropdown}
              className={`text-white ${
                page === "matchJd"
                  ? "bg-blue-700 hover:bg-blue-800"
                  : "bg-green-700 hover:bg-green-800"
              } font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center`}
              type="button"
            >
              {selectedFirstOption}
              <svg
                className="w-2.5 h-2.5 ms-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            {isFirstDropdownOpen && (
              <div className="z-10 absolute bg-white dark:bg-gray-700 divide-y divide-gray-100 rounded-lg h-[25vh] shadow w-44 overflow-y-auto max-w-full">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton1"
                >
                  {[
                    "Full Stack",
                    "Backend",
                    "Front End",
                    "Blockchain",
                    "Gaming",
                    "iOS",
                    "Android",
                    "Data Analyst",
                    "DevOps",
                    "Site Reliability",
                    "QA Testing",
                  ].map((role) => (
                    <li key={role}>
                      <button
                        onClick={() => handleFirstOptionClick(role)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {role}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div>
            <button
              id="dropdownDefaultButton2"
              onClick={toggleSecondDropdown}
              className={`text-white ${
                page === "matchJd"
                  ? "bg-blue-700 hover:bg-blue-800"
                  : "bg-green-700 hover:bg-green-800"
              } font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center`}
              type="button"
            >
              {selectedSecondOption}
              <svg
                className="w-2.5 h-2.5 ms-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {isSecondDropdownOpen && (
              <div className="z-10 absolute bg-white dark:bg-gray-700 divide-y h-[25vh] divide-gray-100 rounded-lg shadow w-44 overflow-y-auto max-w-full">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton2"
                >
                  {["Intern", "Junior", "Mid", "Senior", "Lead", "Manager"].map(
                    (level) => (
                      <li key={level}>
                        <button
                          onClick={() => handleSecondOptionClick(level)}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          {level}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>

          <div>
            <button
              className={`w-full ${
                page !== "matchJd" && "hidden"
              } text-black bg-green-200 hover:bg-green-300 rounded-lg text-sm px-5 py-2.5`}
              type="submit"
              onClick={handleGenerate}
            >
              Generate
            </button>
          </div>
        </div>

        <textarea
          id="message"
          rows={4}
          ref={textareaRef}
          className={`block p-2.5 w-full text-sm ${
            page !== "matchJd" && "hidden"
          } ${
            theme === "dark"
              ? "bg-gray-700 text-white"
              : "bg-gray-50 text-gray-900"
          } rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500`}
          placeholder="Your message..."
          onChange={handleTextareaChange}
        />
        {page === "matchJd" && (
          <p className="text-sm text-gray-500 mt-1">
            {charCount}/{maxChars} characters
          </p>
        )}
      </div>
    </div>
  );
};

export default MatchText;
