import React from "react";

import SignUpButton from "../components/auth/SignUp";

const Login = () => {
  return (
    <div>
      <SignUpButton />
    </div>
  );
};

export default Login;
