import React from "react";
import { CircleProgress } from "react-gradient-progress";
import {
  getColorForPercentage,
  getBackgroundColor,
  getTextColor,
} from "../Demo/Skills";
const PercentageShow = ({ percentage }) => {
  const baseColor = getColorForPercentage(percentage);
  const backgroundColor = getBackgroundColor(baseColor);
  const textColor = getTextColor(baseColor);
  return (
    <CircleProgress
      percentage={percentage}
      width={150}
      strokeWidth={10}
      fontSize={20}
      fontColor={textColor}
      fill={backgroundColor}
      primaryColor={[textColor, textColor]}
    />
  );
};

export default PercentageShow;
