import React from "react";
import JobApply from "../components/jobApply/JobApply";

const JobPage = () => {
  return (
    <div>
      <JobApply />
    </div>
  );
};

export default JobPage;
