import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-center h-[80vh] ">
      <iframe
        src="https://lottie.host/embed/d7b75998-b9d7-4fbf-bf28-5b1aed61a0c1/gVthewYNZ9.json"
        width="500"
        height="500"
        title="Payment Success Animation"
      ></iframe>
      <h1 className="text-3xl font-bold text-green-600 mt-8">
        Payment Successful!
      </h1>
      <p className="text-lg text-gray-700 mt-4">
        Thank you for your purchase. Your transaction has been completed
        successfully.
      </p>
      <button
        onClick={goToHome}
        className="mt-8 px-6 py-3 bg-indigo-500 rounded-lg text-white text-lg font-medium  hover:bg-indigo-600 focus:outline-none"
      >
        Go to Homepage
      </button>
    </div>
  );
};

export default PaymentSuccess;
