import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Login from "../src/pages/Login";
import Signup from "../src/pages/Signup";
import "./App.css";
import HomePage from "./pages/HomePage";
import MatchWithJd from "./pages/MatchWithJd";
import Layout from "./components/Layout";
import Demo from "./pages/Demo";
import ResumeHistory from "./pages/ResumeHistory";
import ResumeEnhancePage from "./pages/ResumeEnhancePage";
import { Analytics } from "@vercel/analytics/react";
import OtpPage from "./pages/OtpPage";
import BuyCredits from "./components/buycredits/BuyCredits";

import EnterEmail from "./components/auth/EnterEmail";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailed from "./pages/PaymentFailed";
import JobPage from "./pages/JobPage";
import ContactPage from "./pages/ContactPage";
import QueryFormPage from "./pages/QueryFormPage";
import { ThemeContext, ThemeProvider } from "./ThemeContext";
import { useContext } from "react";
import MakeResume from "./pages/MakeResume";
function AppContent() {
  const location = useLocation();
  const isAuthPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/signup/otpform" ||
    location.pathname === "/enter-email" ||
    location.pathname === "/login/otpform";

  return (
    <>
      {isAuthPage ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/:pageType/otpform" element={<OtpPage />} />
          <Route path="/enter-email" element={<EnterEmail />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/" element={<Demo />} /> */}
            <Route path="/makeresume" element={<MakeResume />} />
            <Route path="/matchwithjd" element={<MatchWithJd />} />
            <Route path="/resumehistory" element={<ResumeHistory />} />
            <Route path="/resumeenhance" element={<ResumeEnhancePage />} />
            <Route path="/buycredits" element={<BuyCredits />} />
            <Route path="/contactus" element={<ContactPage />} />
            <Route path="/query-form" element={<QueryFormPage />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/job-apply" element={<JobPage />} />
          </Routes>
        </Layout>
      )}
    </>
  );
}

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <Router>
      <div
        className={`min-h-screen transition-colors duration-300 ${
          theme === "light" ? "bg-[#FBF8F7]" : "bg-gray-900"
        }`}
      >
        <AppContent />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
