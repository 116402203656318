import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();

  const tryAgain = () => {
    navigate("/buycredits");
  };
  return (
    <div className="flex flex-col items-center justify-center h-[80vh] ">
      <iframe
        width="500"
        height="500"
        src="https://lottie.host/embed/ee48151b-6b31-4468-9b6f-1d35fcaeda13/7F6JgPvn4x.json"
      ></iframe>
      ;<h1 className="text-3xl font-bold text-red-600 mt-8">Payment Failed!</h1>
      <p className="text-lg text-gray-700 mt-4">
        Thank you for your purchase attempt. Your transaction has been failed
        Please Try again.
      </p>
      <button
        onClick={tryAgain}
        className="mt-8 px-6 py-3 bg-indigo-500 rounded-lg text-white text-lg font-medium  hover:bg-indigo-600 focus:outline-none"
      >
        Try Again
      </button>
    </div>
  );
};

export default PaymentFailed;
