import axios from "axios";
const API_URL = "https://matchmyresumebackend.vercel.app";
// const API_URL = "http://localhost:8080";
const uploadFile = async (file, token, setprogress) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(`${API_URL}/api/files/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
      onUploadProgress: (progressEvent) => {
        const percentageComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setprogress(percentageComplete);
      },
    });
    console.log(response, "resumedt");

    return response.data;
  } catch (error) {
    throw new Error(error.message || "Failed to upload file.");
  }
};

export { uploadFile };
