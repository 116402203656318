import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../Loading";

const QueryForm = ({ onFormSubmit }) => {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState({
    isEasyToUse: "",
    pageNameEasyToUse: "",
    isResponsive: "",
    pageNameResponsive: "",
    isJobRoleMissing: "",
    missingJobRole: "",
    includeJobs: "",
    additionalFeedback: "",
  });

  const {
    isEasyToUse,
    pageNameEasyToUse,
    isResponsive,
    pageNameResponsive,
    isJobRoleMissing,
    missingJobRole,
    includeJobs,
    additionalFeedback,
  } = formData;

  useEffect(() => {
    const storedEmail = Cookies.get("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();

    const email = Cookies.get("email");
    if (!email) {
      toast.error("Please log in to submit the form.");
      setloading(false);
      window.location.href = "/signup"; // Redirect to signup page
      return;
    }
    if (
      !isEasyToUse ||
      (isEasyToUse === "no" && !pageNameEasyToUse) ||
      !isResponsive ||
      (isResponsive === "no" && !pageNameResponsive) ||
      !isJobRoleMissing ||
      (isJobRoleMissing === "yes" && !missingJobRole) ||
      !includeJobs ||
      !additionalFeedback.trim()
    ) {
      toast.error("Please fill out all required fields.");
      setloading(false);
      return;
    }

    try {
      await axios.post(
        "https://matchmyresumebackend.vercel.app/api/query-form",
        // "http://localhost:8080/api/query-form",
        {
          email,
          formData,
        }
      );
      Cookies.set("formSubmitted", true);
      setloading(false);
      toast.success("Your feedback has been submitted successfully.");
      onFormSubmit();
      setFormData({
        isEasyToUse: "",
        pageNameEasyToUse: "",
        isResponsive: "",
        pageNameResponsive: "",
        isJobRoleMissing: "",
        missingJobRole: "",
        includeJobs: "",
        additionalFeedback: "",
      });
    } catch (error) {
      console.error(error);
      setloading(false);
      toast.error("An error occurred while submitting your feedback.");
    }
  };

  return (
    <div>
      {loading && <Loading work="Getting Your Free Credits Ready.." />}
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 my-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Query Form
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              We appreciate your feedback. Please fill out this form.
            </p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <label className="leading-7 text-sm text-gray-600">
                    Is the website easy to use?
                  </label>
                  <select
                    name="isEasyToUse"
                    value={isEasyToUse}
                    onChange={handleChange}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {isEasyToUse === "no" && (
                    <div className="mt-2">
                      <label className="leading-7 text-sm text-gray-600">
                        Which page is not easy to use?
                      </label>

                      <select
                        name="pageNameEasyToUse"
                        value={pageNameEasyToUse}
                        onChange={handleChange}
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                      >
                        <option value="">Select</option>
                        <option value="resumeMatcher">Resume Matcher</option>
                        <option value="resumeEnhancer">Resume Enhancer</option>
                        <option value="resumeHistory">Resume History</option>
                      </select>
                    </div>
                  )}
                </div>

                <div className="p-2 w-1/2">
                  <label className="leading-7 text-sm text-gray-600">
                    Is the website mobile responsive?
                  </label>
                  <select
                    name="isResponsive"
                    value={isResponsive}
                    onChange={handleChange}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {isResponsive === "no" && (
                    <div className="mt-2">
                      <label className="leading-7 text-sm text-gray-600">
                        Which page is not mobile responsive?
                      </label>
                      <select
                        name="pageNameResponsive"
                        value={pageNameResponsive}
                        onChange={handleChange}
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                      >
                        <option value="">Select</option>
                        <option value="resumeMatcher">Resume Matcher</option>
                        <option value="resumeEnhancer">Resume Enhancer</option>
                        <option value="resumeHistory">Resume History</option>
                      </select>
                    </div>
                  )}
                </div>

                <div className="p-2 w-full">
                  <label className="leading-7 text-sm text-gray-600">
                    Is any job role missing?
                  </label>
                  <select
                    name="isJobRoleMissing"
                    value={isJobRoleMissing}
                    onChange={handleChange}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {isJobRoleMissing === "yes" && (
                    <div className="mt-2">
                      <label className="leading-7 text-sm text-gray-600">
                        Job role name:
                      </label>
                      <input
                        type="text"
                        name="missingJobRole"
                        value={missingJobRole}
                        onChange={handleChange}
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3"
                      />
                    </div>
                  )}
                </div>

                <div className="p-2 w-full">
                  <label className="leading-7 text-sm text-gray-600">
                    Is Jobs Section being useful ?
                  </label>
                  <select
                    name="includeJobs"
                    value={includeJobs}
                    onChange={handleChange}
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="p-2 w-full">
                  <label className="leading-7 text-sm text-gray-600">
                    Additional Suggestions or Issues:
                  </label>
                  <textarea
                    name="additionalFeedback"
                    value={additionalFeedback}
                    onChange={handleChange}
                    placeholder="Useful suggestions or Issues would get extra credits"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6"
                  />
                </div>

                <div className="p-2 w-full">
                  <button
                    type="submit"
                    className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default QueryForm;
