import React from "react";

import img from "../images/Designer5.png";

import { Link } from "react-router-dom";
const Page404 = ({ main, desc, page }) => {
  return (
    <div>
      <div className=" py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-lg px-4 md:px-8">
          <div className="grid gap-8 sm:grid-cols-2">
            <div className="flex flex-col items-center justify-center sm:items-start md:py-24 lg:py-32">
              <h1 className="mb-2 text-center text-2xl font-bold text-gray-800 sm:text-left md:text-3xl">
                {main}
              </h1>
              <p className="mb-8 text-center text-gray-500 sm:text-left md:text-lg">
                {desc}
              </p>
              {page === "resumehistory" ? (
                <Link
                  to="/matchwithjd"
                  className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100  hover:bg-green-200 expand focus-visible:ring active:text-gray-700 md:text-base"
                >
                  Match Resume 🌟
                </Link>
              ) : (
                <Link
                  to="/matchwithjd"
                  className="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100  hover:bg-green-200 expand focus-visible:ring active:text-gray-700 md:text-base"
                >
                  Search Your Job 🌟
                </Link>
              )}
            </div>

            <div className="relative h-80 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
              <img
                src={img}
                loading="lazy"
                alt="Photo by @heydevn"
                className="absolute inset-0 h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
