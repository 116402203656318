import React, { useContext, useEffect, useState } from "react";
import ResumeUpload from "./ResumeUpload";
import MatchText from "./MatchText";
import { ResumeSkillsDemo, JdSkillsDemo } from "../Demo/Skills";
import UseTextRazor from "./TextRazer";
import ShowSkills from "./ShowSkills";
import SuggestionAi from "./Aicomp/SuggestionAi";
import PercentageShow from "./PercentageShow";
import PercentReview from "./PercentReview";
import { FaArrowsRotate } from "react-icons/fa6";
import { decreaseCredits, fetchCredits } from "../../services/GetResumeService";
import Loading from "../Loading";
import Cookies from "js-cookie";
import Tour from "reactour";
import { toast, ToastContainer } from "react-toastify";
import { ThemeContext } from "../../ThemeContext";
const MatchWithjdpage = () => {
  const [percentage, setpercentage] = useState(0);
  const [resumeData, setResumeData] = useState("");
  const [jdData, setJdData] = useState("");
  const [resumeSkills, setResumeSkills] = useState([]);
  const [jdSkills, setJdSkills] = useState([]);
  const [matchCredits, setmatchCredits] = useState(0);
  const [skillsPresent, setSkillsPresent] = useState([]);
  const [skillsAbsent, setSkillsAbsent] = useState([]);
  const [loading, setLoading] = useState(false);
  const having = "having";
  const nothaving = "nothaving";
  const apiKey = "a999c06c9af33d4c94db192acb5eef62b4688ff121b06f0af833fc0e";

  const [fetchData, setFetchData] = useState(false);

  const extractSkillsArray = (response) => {
    if (response.ok && response.response && response.response.entities) {
      return response.response.entities.map((entity) => entity.entityEnglishId);
    }
    return [];
  };
  const extractSkills = async (textData) => {
    const url = "https://matchmyresumebackend.vercel.app/api/files/textrazor";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: textData }),
      });

      const result = await response.json();
      return extractSkillsArray(result);
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const processSkills = (jdSkills, resumeSkills) => {
    const presentSkills = [];
    const absentSkills = [];

    for (const skill of jdSkills) {
      if (resumeSkills.includes(skill)) {
        presentSkills.push(skill);
      } else {
        absentSkills.push(skill);
      }
    }

    setSkillsPresent(presentSkills);
    setSkillsAbsent(absentSkills);

    const per = Math.round(
      (presentSkills.length / (presentSkills.length + absentSkills.length)) *
        100
    );
    setpercentage(per);
  };

  useEffect(() => {
    if (fetchData) {
      const getResults = async () => {
        setLoading(true);

        let resumeSkills = [];
        let jdSkills = [];

        if (resumeData) {
          resumeSkills = await extractSkills(resumeData);
          setResumeSkills(resumeSkills);
        } else {
          toast.error("Please upload resume first for getting your score.", {
            position: "top-right",
          });
        }

        if (jdData) {
          jdSkills = await extractSkills(jdData);

          setJdSkills(jdSkills);
        } else {
          toast.error(
            "Please apply job description first for getting your score.",
            {
              position: "top-right",
            }
          );
        }

        if (resumeSkills.length > 0 && jdSkills.length > 0) {
          processSkills(jdSkills, resumeSkills);
        }

        setLoading(false);
        setFetchData(false);

        try {
          if (resumeSkills.length > 0 && jdSkills.length > 0) {
            // Decrease credits using the API
            const updatedCredits = await decreaseCredits(Cookies.get("token"));

            // Update the credits in the state
            setmatchCredits(updatedCredits);

            // Store the updated credits in cookies
            Cookies.set("matchCredits", updatedCredits, { expires: 1 }); // Expires in 1 day or set as needed
          } else {
            console.log("No skills found in resume or JD.");
          }
        } catch (error) {
          console.error("Failed to decrease credits:", error);
        }
      };

      getResults();
    }
  }, [fetchData, resumeData, jdData]);

  useEffect(() => {
    const getCredits = async () => {
      try {
        // First, check if credits are stored in cookies
        const creditsFromCookies = Cookies.get("matchCredits");

        if (creditsFromCookies) {
          // If found, parse and set the credits from cookies
          setmatchCredits(parseInt(creditsFromCookies, 10));
        } else {
          // If not found, fetch from API
          const initialCredits = await fetchCredits(Cookies.get("token"));

          // Set credits in state
          setmatchCredits(initialCredits.credits);

          // Store the fetched credits in cookies
          Cookies.set("matchCredits", initialCredits.credits, { expires: 1 }); // Expires in 1 day or set duration as needed
        }
      } catch (error) {
        console.error("Failed to fetch credits:", error);
      }
    };

    getCredits();
  }, []);

  useEffect(() => {
    // console.log("Resume Skills:", resumeSkills);
    // console.log("JD Skills:", jdSkills);
    // console.log("Skills Present:", skillsPresent);
    // console.log("Skills Absent:", skillsAbsent);
    // console.log("Percentage:", percentage);
  }, [resumeSkills, jdSkills, skillsPresent, skillsAbsent, percentage]);

  const handleGetResult = () => {
    if (matchCredits <= 0) {
      toast.error(
        "No credits left. Please purchase more credits to continue.",
        {
          position: "top-right",
        }
      );
      return;
    }
    setFetchData(true);
  };

  const [isTourOpen, setIsTourOpen] = useState(false);

  const steps = [
    {
      selector: ".my-first-step",
      content: "First Sign-up and then Upload Your resume here",
    },
    {
      selector: ".my-second-step",
      content:
        "Paste Job description , if not select desired domain and experience",
    },
    {
      selector: ".my-third-step",
      content: "Press here to get score and skills to be added",
    },
  ];
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`flex flex-col ${
        theme === "dark" ? " text-white" : " text-gray-900"
      }`}
    >
      {loading && <Loading work="loading" />}

      <div className="flex justify-between">
        <div
          className={`relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white ${
            theme === "dark" ? "text-white" : "text-gray-900"
          } focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800`}
        >
          <span
            className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0 ${
              theme === "dark" ? "bg-gray-900" : "bg-white"
            }`}
          >
            Credits Left {matchCredits} ⚡️
          </span>
        </div>

        <button
          onClick={() => setIsTourOpen(true)}
          className={`relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white focus:ring-4 focus:outline-none ${
            theme === "dark"
              ? "text-black dark:focus:ring-green-800"
              : "text-gray-900 focus:ring-green-200"
          }`}
        >
          <span
            className={`relative px-5 py-2.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0 ${
              theme === "dark" ? "bg-white " : "bg-gray-900 text-white"
            }`}
          >
            Take Tour ⚡️
          </span>
        </button>
      </div>

      <div>
        <div className="flex flex-col md:flex-row justify-around items-center">
          <div className="my-first-step">
            <ResumeUpload setResumeData={setResumeData} />
          </div>
          <div className="my-second-step">
            <MatchText
              setJdData={setJdData}
              setOptionsData={""}
              page="matchJd"
            />
          </div>
        </div>

        {skillsAbsent.length > 0 && skillsPresent.length > 0 && (
          <div>
            <div className="flex flex-col md:flex-row justify-evenly opacity-70 mt-10">
              <PercentageShow percentage={percentage} />
              <PercentReview percentage={percentage} />
            </div>

            <div className="flex flex-col md:flex-row justify-around mt-5">
              <ShowSkills
                skillData={skillsPresent}
                skillsDo={having}
                match={"Matched keywords"}
              />
              <ShowSkills
                skillData={skillsAbsent}
                skillsDo={nothaving}
                match={"Suggested keywords"}
              />
            </div>
          </div>
        )}

        <div className="flex justify-center mt-10">
          <button
            className={`my-third-step bg-white border-2 my-2 py-2 hover:border-[#9a8def] hover:text-[#9a8def] px-8 border-[#7460ff] rounded-lg text-[#7460ff] ${
              theme === "light" ? " text-gray-900" : " "
            }`}
            onClick={handleGetResult}
          >
            {skillsAbsent.length > 0 && skillsPresent.length > 0 ? (
              <div className="flex items-center space-x-2">
                <FaArrowsRotate /> <p>Check Again</p>
              </div>
            ) : (
              "Get Result"
            )}
          </button>
        </div>
      </div>

      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <ToastContainer />
    </div>
  );
};

export default MatchWithjdpage;
